// third-party
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// project imports
import calendarReducer from "./slices/calendar";
import cartReducer from "./slices/cart";
import chatReducer from "./slices/chat";
import contactReducer from "./slices/contact";
import customerReducer from "./slices/customer";
import kanbanReducer from "./slices/kanban";
import mailReducer from "./slices/mail";
import menuReducer from "./slices/menu";
import productReducer from "./slices/product";
import snackbarReducer from "./slices/snackbar";
import userReducer from "./slices/user";

import loadingReducer from "./slices/loading";
import notifyReducer from "./slices/notify";
import dialogReducer from "./slices/dialog";
import taskReducer from "./slices/task";
import saleCycleReducer from "./slices/saleCycle";
import breadcrumbReducer from "./slices/breadcrumb";
import fetchReducer from "./slices/fetch";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
	snackbar: snackbarReducer,
	cart: persistReducer(
		{
			key: "cart",
			storage,
			keyPrefix: "berry-"
		},
		cartReducer
	),
	kanban: kanbanReducer,
	customer: customerReducer,
	contact: contactReducer,
	product: productReducer,
	chat: chatReducer,
	calendar: calendarReducer,
	mail: mailReducer,
	user: userReducer,
	menu: menuReducer,
	loading: loadingReducer,
	notify: notifyReducer,
	dialog: dialogReducer,
	task: taskReducer,
	breadcrumb: breadcrumbReducer,
	saleCycle: saleCycleReducer,
	fetch: fetchReducer
});

export default reducer;
