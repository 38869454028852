// load mock apis
//import '_mockApis';
// project imports
import App from "App";
// style + assets
import "assets/scss/style.scss";
import "assets/scss/style.custom.scss";
import "assets/scss/style.qdung.scss";
import { BASE_PATH } from "config";
import { ConfigProvider } from "contexts/ConfigContext";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
// third party
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import reportWebVitals from "reportWebVitals";
import * as serviceWorker from "serviceWorker";
import { persister, store } from "store";
import "./utils/i18n";
// ==============================|| REACT DOM RENDER  ||============================== //
ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persister}>
			<ConfigProvider>
				<BrowserRouter basename={BASE_PATH}>
					<App />
				</BrowserRouter>
			</ConfigProvider>
		</PersistGate>
	</Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
