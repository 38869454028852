import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultRootStateProps } from 'types';
import { NotifyStateProps } from 'types/notify';

const initialState: DefaultRootStateProps['notify'] = {
    isShow: false,
    typeMsg: 'success',
    dataMsg: new Array<string>(0),
};
const slice = createSlice({
    name: 'notify-slice',
    initialState,
    reducers: {
        showNotify: (state, action: PayloadAction<NotifyStateProps>) => {
            state.isShow = true;
            state.typeMsg = action.payload.typeMsg;
            state.dataMsg = action.payload.dataMsg;
        },
        hideNotify: (state) => {
            state.isShow = false;
        },
    },
});
export default slice.reducer;
export const { showNotify, hideNotify } = slice.actions;
