import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";

export const firebaseConfig = {
	apiKey: "AIzaSyB2FjZ1wdJTXakiZVcT_jvAL2xnKJeKm0I",
	authDomain: "roamie-7b103.firebaseapp.com",
	databaseURL: "https://roamie-7b103-default-rtdb.asia-southeast1.firebasedatabase.app",
	projectId: "roamie-7b103",
	storageBucket: "roamie-7b103.appspot.com",
	messagingSenderId: "983591290156",
	appId: "1:983591290156:web:4753d224d9be93d6c79889",
	measurementId: "G-S1T342EB9B"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const realtimeDb = getDatabase(app);
