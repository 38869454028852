import AddIcon from "@mui/icons-material/AddTwoTone";
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	InputAdornment,
	Slide,
	SlideProps,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from "@mui/material";
import { IconSearch } from "@tabler/icons";
import { END_POINT } from "configs";
import { MyOutlinedInputSearch } from "control/MyOutlinedInputSearch";
import produce from "immer";
import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { openSnackbar } from "store/slices/snackbar";
import AnimateButton from "ui-component/extended/AnimateButton";
import axios from "utils/axios";
const Transition = forwardRef((props: SlideProps, ref) => <Slide direction="left" ref={ref} {...props} />);

// ==============================|| PRODUCT ADD DIALOG ||============================== //
interface ITargetItem {
	id: number;
	name?: string;
	due_in?: string;
	status?: number;
}
interface TargetChooseProps {
	isOpenTargetChooseFrm: boolean;
	closeTargetChooseFrm: () => void;
	getTargetSelected: (targetSelected: ITargetItem[]) => void;
	callback: (targetIem: ITargetItem) => void;
	multiple: boolean;
}

const TargetChooseFrm = ({
	isOpenTargetChooseFrm,
	closeTargetChooseFrm,
	getTargetSelected,
	callback,
	multiple = true
}: TargetChooseProps) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [rows, setRows] = React.useState<ITargetItem[]>([]);
	const [selected, setSelected] = React.useState<ITargetItem[]>([]);
	const [search, setSearch] = React.useState<string>("");
	const isSelected = (id: number) => selected.findIndex((elmt) => elmt.id === id) !== -1;
	let mounted = true;
	React.useEffect(() => {
		const getList = async () => {
			if (isOpenTargetChooseFrm) {
				const res: any = await axios.get(END_POINT.API_GET_TARGET_LIST_NOT_PANGATION, {
					params: { name: search }
				});
				if (mounted) {
					const { status, message } = res.data;
					if (status) {
						setRows(res.data.data.items);
					}
				}
			}
		};
		getList();
		return () => {
			mounted = false;
		};
	}, [isOpenTargetChooseFrm, search]);
	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			setSelected(rows);
		} else {
			setSelected([]);
		}
	};
	const handleSelectedItem = (event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>, itemChecked: ITargetItem) => {
		const itemFounded: ITargetItem | undefined = selected.find((elmt) => elmt.id === itemChecked.id);
		if (itemFounded) {
			const nextState: ITargetItem[] = produce(selected, (draft) => {
				draft = selected.filter((item) => item.id !== itemFounded.id);
			});
			setSelected([]);
		} else {
			if (selected.length > 0) {
				dispatch(
					openSnackbar({
						open: true,
						message: t("only_choose_one_target"),
						anchorOrigin: { vertical: "bottom", horizontal: "left" },
						variant: "alert",
						alert: {
							color: "error"
						},
						transition: "Fade",
						close: false
					})
				);
			} else {
				const nextState: ITargetItem[] = produce(selected, (draft) => {
					draft.push(itemChecked);
				});
				setSelected(nextState);
			}
		}
	};
	const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
		const newString = event?.target.value;
		setSearch(newString || "");
	};
	const handleGetTargetSelected = () => {
		if (multiple === false && selected.length > 1) {
			alert("You only choose one target");
			return;
		}
		getTargetSelected(selected);
		callback(selected[0]);
		closeTargetChooseFrm();
	};
	return (
		<Dialog
			open={isOpenTargetChooseFrm}
			TransitionComponent={Transition}
			keepMounted
			onClose={closeTargetChooseFrm}
			sx={{
				"&>div:nth-of-type(3)": {
					justifyContent: "flex-end",
					"&>div": {
						m: 0,
						borderRadius: "0px",
						maxWidth: 450,
						maxHeight: "100vh",
						height: "100vh"
					}
				}
			}}
		>
			{isOpenTargetChooseFrm && (
				<React.Fragment>
					<DialogTitle>
						<FormattedMessage id="choose_target" />
					</DialogTitle>
					<DialogContent>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<MyOutlinedInputSearch
									onChange={handleSearch}
									value={search}
									fullWidth
									id="input-search-list-style1"
									placeholder={t("search_list")}
									startAdornment={
										<InputAdornment position="start" sx={{ borderRadius: "4px" }}>
											<IconSearch stroke={1.5} size="16px" />
										</InputAdornment>
									}
									size="small"
								/>
							</Grid>
							<Grid item xs={12}>
								<TableContainer>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell padding="checkbox">
													{multiple === true && (
														<Checkbox
															color="primary"
															indeterminate={selected.length > 0 && selected.length < rows.length}
															checked={rows.length > 0 && selected.length === rows.length}
															onChange={handleSelectAllClick}
															inputProps={{
																"aria-label": "select all desserts"
															}}
														/>
													)}
												</TableCell>
												<TableCell>
													<FormattedMessage id="name" />
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{rows &&
												rows.map((row, idx) => {
													const isItemSelected = isSelected(row.id);
													const labelId = `enhanced-table-checkbox-${idx}`;
													return (
														<TableRow hover key={"product-service-tbl-" + row.id}>
															<TableCell
																padding="checkbox"
																onClick={(event) => handleSelectedItem(event, row)}
															>
																<Checkbox
																	color="primary"
																	checked={isItemSelected}
																	inputProps={{
																		"aria-labelledby": labelId
																	}}
																/>
															</TableCell>
															<TableCell>{row.name}</TableCell>
														</TableRow>
													);
												})}
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<AnimateButton>
							<Button
								variant="contained"
								type="button"
								startIcon={<AddIcon />}
								sx={{
									bgcolor: "primary.main",
									"&:hover": {
										bgcolor: "primary.dark"
									}
								}}
								onClick={handleGetTargetSelected}
							>
								<FormattedMessage id="choose" />
							</Button>
						</AnimateButton>
						<Button variant="text" color="error" onClick={closeTargetChooseFrm}>
							<FormattedMessage id="close" />
						</Button>
					</DialogActions>
				</React.Fragment>
			)}
		</Dialog>
	);
};

export default TargetChooseFrm;
