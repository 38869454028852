import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DefaultRootStateProps } from "types";
import { TaskStateProps } from "types/i-task";

const initialState: DefaultRootStateProps["task"] = {
	taskSchedules: [],
	taskCalendars: [],
	dateSelected: new Date(),
	today_obj: { rows: [], page: 0, total_item: 0, perpage: 10 },
	upcoming_obj: { rows: [], page: 0, total_item: 0, perpage: 10 },
	done_obj: { rows: [], page: 0, total_item: 0, perpage: 10 },
	overdue_obj: { rows: [], page: 0, total_item: 0, perpage: 10 }
};
const slice = createSlice({
	name: "task-slice",
	initialState,
	reducers: {
		setTaskSchedules: (state, action: PayloadAction<TaskStateProps>) => {
			state.taskSchedules = action.payload.taskSchedules;
		},
		setTaskCalendars: (state, action: PayloadAction<TaskStateProps>) => {
			state.taskCalendars = action.payload.taskCalendars;
		},
		setDateSelected: (state, action: PayloadAction<TaskStateProps>) => {
			state.dateSelected = action.payload.dateSelected;
		},
		setTodayObj: (state, action: PayloadAction<TaskStateProps>) => {
			state.today_obj = action.payload.today_obj;
		},
		setUpcomingObj: (state, action: PayloadAction<TaskStateProps>) => {
			state.upcoming_obj = action.payload.upcoming_obj;
		},
		setDoneObj: (state, action: PayloadAction<TaskStateProps>) => {
			state.done_obj = action.payload.done_obj;
		},
		setOverDueObj: (state, action: PayloadAction<TaskStateProps>) => {
			state.overdue_obj = action.payload.overdue_obj;
		}
	}
});
export default slice.reducer;
export const { setTaskSchedules, setTaskCalendars, setDateSelected, setTodayObj, setUpcomingObj, setDoneObj, setOverDueObj } =
	slice.actions;
