import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import axios from "utils/axios";
import TaskFrm from "views/task/TaskFrm";
import SelectedTargetTaskDone from "./SelectedTargetTaskDone";
interface HowMeetingDoneApproachProps {
	isOpenHowMeetingDoneApproach: boolean;
	onCloseHowMeetingDoneApproach: () => void;
	meetingPrefix: string;
	leadId: number;
}
interface IProduct {
	id: number;
	name: string;
}
interface IDeal {
	id: number;
	value: number;
	close_date: string;
	type: string;
	note: string;
	product_service_data: IProduct[];
}
const HowMeetingDoneApproach: React.FC<HowMeetingDoneApproachProps> = React.memo(
	({ isOpenHowMeetingDoneApproach, onCloseHowMeetingDoneApproach, meetingPrefix, leadId }) => {
		const { t } = useTranslation();
		const theme = useTheme();
		const dispatch = useDispatch();
		const navigate = useNavigate();
		const [isOpenSelectedTargetTaskDone, setOpenConfirmedSelectTarget] = React.useState<boolean>(false);
		const [pointTypeSubFirst, setPointTypeSubFirst] = React.useState<string>("");
		const [pointTypeSubSecond, setPointTypeSubSecond] = React.useState<string>("");
		const [isOpenTaskFrm, setOpenTaskFrm] = React.useState<boolean>(false);
		const [previousStep, setPreviousStep] = React.useState<number>(0);
		const [completedStep, setCompletedStep] = React.useState<number>(0);
		const [dealData, setDealData] = React.useState<IDeal[]>([]);
		let mounted: boolean = true;
		React.useEffect(() => {
			const init = () => {
				if (isOpenHowMeetingDoneApproach && leadId) {
					axios
						.get(`/lead/show/${leadId}`, { headers: { isShowLoading: true } })
						.then((res) => {
							if (mounted) {
								const { status, data, message } = res.data;
								if (status) {
									setPreviousStep(parseInt(data.items.previous_step));
									setCompletedStep(parseInt(data.items.completed_step));
									setDealData(data.items.deal && data.items.deal.length > 0 ? data.items.deal : []);
								} else {
									dispatch(
										openSnackbar({
											open: true,
											message,
											anchorOrigin: { vertical: "bottom", horizontal: "left" },
											variant: "alert",
											alert: {
												color: "error"
											},
											transition: "Fade",
											close: false
										})
									);
								}
							}
						})
						.catch((err: any) => {
							dispatch(
								openSnackbar({
									open: true,
									message: err && err.data && err.data.exception ? err.data.exception : "",
									anchorOrigin: { vertical: "bottom", horizontal: "left" },
									variant: "alert",
									alert: {
										color: "error"
									},
									transition: "Fade",
									close: false
								})
							);
						});
				}
			};
			init();
			return () => {
				mounted = false;
			};
		}, [isOpenHowMeetingDoneApproach, leadId]);
		const handleLeadNotTurnUp = () => {
			if (leadId && dealData && dealData.length === 0) {
				const makePointParams: any = {
					lead_id: leadId,
					point_type: "meeting",
					point_type_sub_first: meetingPrefix,
					point_type_sub_second: "lead_not_turn_up"
				};
				axios
					.post("/lead/make-point", makePointParams, { headers: { isShowLoading: true } })
					.then((res: any) => {
						const { status, data, message } = res.data;
						if (status) {
							dispatch(
								openSnackbar({
									open: true,
									message: t("Point is saved"),
									anchorOrigin: { vertical: "bottom", horizontal: "left" },
									variant: "alert",
									alert: {
										color: "success"
									},
									transition: "Fade",
									close: false
								})
							);
							setOpenConfirmedSelectTarget(true);
							onCloseHowMeetingDoneApproach();
						} else {
							dispatch(
								openSnackbar({
									open: true,
									message,
									anchorOrigin: { vertical: "bottom", horizontal: "left" },
									variant: "alert",
									alert: {
										color: "error"
									},
									transition: "Fade",
									close: false
								})
							);
						}
					})
					.catch((err: any) => {
						dispatch(
							openSnackbar({
								open: true,
								message: err && err.data && err.data.exception ? err.data.exception : "",
								anchorOrigin: { vertical: "bottom", horizontal: "left" },
								variant: "alert",
								alert: {
									color: "error"
								},
								transition: "Fade",
								close: false
							})
						);
					});
			} else {
				dispatch(
					openSnackbar({
						open: true,
						message: t("Lead already got deal. Cannot reject"),
						anchorOrigin: { vertical: "bottom", horizontal: "left" },
						variant: "alert",
						alert: {
							color: "error"
						},
						transition: "Fade",
						close: false
					})
				);
			}
		};
		const handleRejected = () => {
			if (leadId && dealData && dealData.length === 0) {
				const makePointParams: any = {
					lead_id: leadId,
					point_type: "meeting",
					point_type_sub_first: meetingPrefix,
					point_type_sub_second: "rejected"
				};
				axios
					.post("/lead/make-point", makePointParams, { headers: { isShowLoading: true } })
					.then((res: any) => {
						const { status, data, message } = res.data;
						if (status) {
							const leadUpdatedParams: any = {
								previous_step: previousStep,
								step: 7
							};
							axios
								.put(`/lead/update/${leadId}`, leadUpdatedParams, { headers: { isShowLoading: true } })
								.then((leadUpdatedRes: any) => {
									const { status, data, message } = leadUpdatedRes.data;
									if (status) {
										navigate(`/leads/update/${leadId}?task_status=not_now`);
										onCloseHowMeetingDoneApproach();
									} else {
										dispatch(
											openSnackbar({
												open: true,
												message,
												anchorOrigin: { vertical: "bottom", horizontal: "left" },
												variant: "alert",
												alert: {
													color: "error"
												},
												transition: "Fade",
												close: false
											})
										);
									}
								})
								.catch((err: any) => {
									dispatch(
										openSnackbar({
											open: true,
											message: err && err.data && err.data.exception ? err.data.exception : "",
											anchorOrigin: { vertical: "bottom", horizontal: "left" },
											variant: "alert",
											alert: {
												color: "error"
											},
											transition: "Fade",
											close: false
										})
									);
								});
						} else {
							dispatch(
								openSnackbar({
									open: true,
									message,
									anchorOrigin: { vertical: "bottom", horizontal: "left" },
									variant: "alert",
									alert: {
										color: "error"
									},
									transition: "Fade",
									close: false
								})
							);
						}
					})
					.catch((err: any) => {
						dispatch(
							openSnackbar({
								open: true,
								message: err && err.data && err.data.exception ? err.data.exception : "",
								anchorOrigin: { vertical: "bottom", horizontal: "left" },
								variant: "alert",
								alert: {
									color: "error"
								},
								transition: "Fade",
								close: false
							})
						);
					});
			} else {
				dispatch(
					openSnackbar({
						open: true,
						message: t("Lead already got deal. Cannot reject"),
						anchorOrigin: { vertical: "bottom", horizontal: "left" },
						variant: "alert",
						alert: {
							color: "error"
						},
						transition: "Fade",
						close: false
					})
				);
			}
		};
		const handleMakeAppointment = () => {
			setPointTypeSubFirst(meetingPrefix);
			setPointTypeSubSecond("need_1_more_appointment");
			setOpenTaskFrm(true);
			onCloseHowMeetingDoneApproach();
		};
		const handleMakeDeal = () => {
			if (leadId && dealData && dealData.length === 0) {
				const leadUpdatedParams: any = {
					step: 7,
					completed_step: completedStep + 1,
					previous_step: previousStep
				};
				axios
					.put(`/lead/update/${leadId}`, leadUpdatedParams, { headers: { isShowLoading: true } })
					.then((leadUpdatedRes: any) => {
						const { status, data, message } = leadUpdatedRes.data;
						if (status) {
							onCloseHowMeetingDoneApproach();
							navigate(
								`/leads/update/${leadId}?point_type=meeting&point_type_sub_first=${meetingPrefix}&point_type_sub_second=deal_made`
							);
						} else {
							dispatch(
								openSnackbar({
									open: true,
									message,
									anchorOrigin: { vertical: "bottom", horizontal: "left" },
									variant: "alert",
									alert: {
										color: "error"
									},
									transition: "Fade",
									close: false
								})
							);
						}
					})
					.catch((err: any) => {
						dispatch(
							openSnackbar({
								open: true,
								message: err && err.data && err.data.exception ? err.data.exception : "",
								anchorOrigin: { vertical: "bottom", horizontal: "left" },
								variant: "alert",
								alert: {
									color: "error"
								},
								transition: "Fade",
								close: false
							})
						);
					});
			} else {
				dispatch(
					openSnackbar({
						open: true,
						message: t("Lead already got deal. Cannot re-make deal"),
						anchorOrigin: { vertical: "bottom", horizontal: "left" },
						variant: "alert",
						alert: {
							color: "error"
						},
						transition: "Fade",
						close: false
					})
				);
			}
		};
		const handleCloseSelectedTargetTaskDone = React.useCallback(() => {
			setOpenConfirmedSelectTarget(false);
		}, []);
		const handleCloseTaskFrm = React.useCallback(() => {
			setOpenTaskFrm(false);
		}, []);
		return (
			<React.Fragment>
				<Dialog onClose={onCloseHowMeetingDoneApproach} aria-labelledby="simple-dialog-title" open={isOpenHowMeetingDoneApproach}>
					{isOpenHowMeetingDoneApproach && (
						<React.Fragment>
							<DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
								<Box sx={{ color: theme.palette.primary.dark, textAlign: "center", fontSize: 25, fontWeight: "bold" }}>
									{t("How is your meeting")} ?
								</Box>
							</DialogTitle>
							<DialogContent>
								<Box sx={{ boxShadow: 4, p: 1, borderRadius: 2 }}>
									<Button
										sx={{ color: theme.palette.grey[900], fontWeight: "bold", fontSize: 18 }}
										onClick={handleLeadNotTurnUp}
									>
										{t("Lead not turn up")}
									</Button>
								</Box>
								<Box sx={{ boxShadow: 4, mt: 2, p: 1, borderRadius: 2 }}>
									<Button
										sx={{ color: theme.palette.grey[900], fontWeight: "bold", fontSize: 18 }}
										onClick={handleRejected}
									>
										{t("Rejected")}
									</Button>
								</Box>
								<Box sx={{ boxShadow: 4, mt: 2, p: 1, borderRadius: 2 }}>
									<Button
										sx={{ color: theme.palette.grey[900], fontWeight: "bold", fontSize: 18 }}
										onClick={handleMakeAppointment}
									>
										{t("Appointment made")}
									</Button>
								</Box>
								<Box sx={{ boxShadow: 4, mt: 2, p: 1, borderRadius: 2 }}>
									<Button
										sx={{ color: theme.palette.grey[900], fontWeight: "bold", fontSize: 18 }}
										onClick={handleMakeDeal}
									>
										{t("Deal made")}
									</Button>
								</Box>
							</DialogContent>
							<DialogActions>
								<Button
									type="button"
									variant="outlined"
									color="error"
									startIcon={<CloseIcon />}
									onClick={onCloseHowMeetingDoneApproach}
								>
									<FormattedMessage id="close" />
								</Button>
							</DialogActions>
						</React.Fragment>
					)}
				</Dialog>
				<SelectedTargetTaskDone
					isOpenSelectedTargetTaskDone={isOpenSelectedTargetTaskDone}
					onCloseSelectedTargetTaskDone={handleCloseSelectedTargetTaskDone}
					leadId={leadId}
					rejectedType="not_turn_up"
				/>
				<TaskFrm
					isOpenTaskFrm={isOpenTaskFrm}
					closeTaskFrm={handleCloseTaskFrm}
					leadId={leadId}
					typeTask="meeting"
					point_type="meeting"
					point_type_sub_first={pointTypeSubFirst}
					point_type_sub_second={pointTypeSubSecond}
				/>
			</React.Fragment>
		);
	}
);

export default HowMeetingDoneApproach;
