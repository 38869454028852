import React, { useEffect, useRef, useState } from 'react';

// material-ui
import {
    Avatar,
    Box,
    ClickAwayListener,
    Grid,
    List,
    ListItemButton,
    ListItemText,
    Paper,
    Popper,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import Transitions from 'ui-component/extended/Transitions';
// assets
import { END_POINT } from 'configs';
import useAuth from 'hooks/useAuth';
import useConfig from 'hooks/useConfig';
import axios from 'utils/axios';
import { IconBusinessplan } from '@tabler/icons';

// ==============================|| LOCALIZATION ||============================== //
interface ICurrencySaved {
    currency: string;
}
const CurrencySection = () => {
    const { borderRadius, currency, onChangeCurrency } = useConfig();
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<any>(null);
    const handleListItemClick = async (
        event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement, MouseEvent> | undefined,
        currencyParam: string
    ) => {
        onChangeCurrency(currencyParam);
        setOpen(false);
        let data: ICurrencySaved = {
            currency: currencyParam,
        };
        const res: any = await axios.post(END_POINT.API_CHANGE_CURRENCY, data);
        window.location.reload();
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);
    return (
        <React.Fragment>
            <Box
                sx={{
                    ml: 2,
                    [theme.breakpoints.down('md')]: {
                        ml: 1,
                    },
                }}
            >
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        border: '1px solid',
                        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
                        color: theme.palette.success.dark,
                        transition: 'all .2s ease-in-out',
                        '&[aria-controls="menu-list-grow"],&:hover': {
                            borderColor: theme.palette.success.main,
                            background: theme.palette.success.main,
                            color: theme.palette.success.light,
                        },
                        width: 45,
                        height: 45,
                    }}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    color="inherit"
                >
                    {currency !== 'USD' && (
                        <Typography variant="h4" sx={{ textTransform: 'uppercase' }} color="inherit">
                            {currency}
                        </Typography>
                    )}
                    {currency === 'USD' && <IconBusinessplan />}
                </Avatar>
            </Box>

            <Popper
                placement={matchesXs ? 'bottom-start' : 'bottom'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 0 : 0, 20],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions position={matchesXs ? 'top-left' : 'top'} in={open} {...TransitionProps}>
                            <Paper elevation={16}>
                                {open && (
                                    <List
                                        component="nav"
                                        sx={{
                                            width: '100%',
                                            minWidth: 200,
                                            maxWidth: 280,
                                            bgcolor: theme.palette.background.paper,
                                            borderRadius: `${borderRadius}px`,
                                            [theme.breakpoints.down('md')]: {
                                                maxWidth: 250,
                                            },
                                        }}
                                    >
                                        <ListItemButton
                                            selected={currency === 'USD'}
                                            onClick={(event) => handleListItemClick(event, 'USD')}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Grid container>
                                                        <Typography color="textPrimary">USD</Typography>
                                                    </Grid>
                                                }
                                            />
                                        </ListItemButton>
                                        <ListItemButton
                                            selected={currency === 'VND'}
                                            onClick={(event) => handleListItemClick(event, 'VND')}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Grid container>
                                                        <Typography color="textPrimary">VND</Typography>
                                                    </Grid>
                                                }
                                            />
                                        </ListItemButton>
                                        <ListItemButton
                                            selected={currency === 'SGP'}
                                            onClick={(event) => handleListItemClick(event, 'SGP')}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Grid container>
                                                        <Typography color="textPrimary">SGP</Typography>
                                                    </Grid>
                                                }
                                            />
                                        </ListItemButton>
                                    </List>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </React.Fragment>
    );
};

export default CurrencySection;
