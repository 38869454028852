import React from 'react';

// material-ui
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'store';
import { agree, hideDialog } from 'store/slices/dialog';

// ===============================|| UI DIALOG - SWEET ALERT ||=============================== //

export default function AlertDialog() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const handleClose = () => {
        dispatch(hideDialog());
    };
    const handleAgree = () => {
        dispatch(hideDialog());
        dispatch(agree());
    };
    const { isShow } = useSelector((state) => state.dialog);
    return (
        <React.Fragment>
            <Dialog open={isShow} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" sx={{ p: 3 }}>
                {isShow && (
                    <>
                        <DialogTitle id="alert-dialog-title">{t('confirmed_delete')}</DialogTitle>
                        <DialogActions sx={{ pr: 2.5 }}>
                            <Button variant="contained" size="small" onClick={handleAgree} autoFocus>
                                <FormattedMessage id="agree" />
                            </Button>
                            <Button sx={{ color: theme.palette.error.dark, borderColor: theme.palette.error.dark }} onClick={handleClose} color="secondary">
                                <FormattedMessage id="disagree" />
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </React.Fragment>
    );
}
