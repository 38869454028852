import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
// assets
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "store";
import axios from "utils/axios";
import TaskFrm from "views/task/TaskFrm";
import { openSnackbar } from "store/slices/snackbar";
import swal from "sweetalert";
import TargetCheckedSidebar from "views/lead/popup/TargetCheckedSidebar";
import TargetAddDialog from "views/target/TargetAddDialog";
import { useNavigate } from "react-router-dom";
interface SelectedTargetTaskDoneProps {
	isOpenSelectedTargetTaskDone: boolean;
	onCloseSelectedTargetTaskDone: () => void;
	leadId: number;
	rejectedType: string;
}
const SelectedTargetTaskDone: React.FC<SelectedTargetTaskDoneProps> = React.memo(
	({ isOpenSelectedTargetTaskDone, onCloseSelectedTargetTaskDone, leadId, rejectedType }) => {
		const { t } = useTranslation();
		const theme = useTheme();
		const navigate = useNavigate();
		const dispatch = useDispatch();
		const [isOpenTaskFrm, setOpenTaskFrm] = React.useState<boolean>(false);
		const [isOpenTargetSidebar, setOpenTargetSidebar] = React.useState<boolean>(false);
		const [isOpenAddTarget, setOpenAddTarget] = React.useState<boolean>(false);
		const [nextStepAddTask, setNextStepAddTask] = React.useState<string>("");
		const [step, setStep] = React.useState<number>(0);
		const [completedStep, setCompletedStep] = React.useState<number>(0);
		const [goalId, setGoalId] = React.useState<number>(0);
		let mounted: boolean = true;
		React.useEffect(() => {
			const init = () => {
				if (isOpenSelectedTargetTaskDone && leadId) {
					axios
						.get(`/lead/show/${leadId}`, { headers: { isShowLoading: true } })
						.then((res) => {
							if (mounted) {
								const { status, data, message } = res.data;
								if (status) {
									setStep(parseInt(data.items.step));
									setCompletedStep(parseInt(data.items.completed_step));
									setGoalId(parseInt(data.items.goal.id));
								} else {
									dispatch(
										openSnackbar({
											open: true,
											message,
											anchorOrigin: { vertical: "bottom", horizontal: "left" },
											variant: "alert",
											alert: {
												color: "error"
											},
											transition: "Fade",
											close: false
										})
									);
								}
							}
						})
						.catch((err: any) => {
							dispatch(
								openSnackbar({
									open: true,
									message: err && err.data && err.data.exception ? err.data.exception : "",
									anchorOrigin: { vertical: "bottom", horizontal: "left" },
									variant: "alert",
									alert: {
										color: "error"
									},
									transition: "Fade",
									close: false
								})
							);
						});
				}
			};
			init();
			return () => {
				mounted = false;
			};
		}, [isOpenSelectedTargetTaskDone, leadId]);
		const handleCheckThisTarget = () => {
			if (leadId && step) {
				const leadUpdatedParams: any = {
					step,
					rejected: 0,
					rejected_step: step,
					rejected_type: rejectedType
				};
				axios
					.put(`/lead/update/${leadId}`, leadUpdatedParams, { headers: { isShowLoading: true } })
					.then((leadUpdatedRes: any) => {
						const { status, data, message } = leadUpdatedRes.data;
						if (status) {
							onCloseSelectedTargetTaskDone();
							swal({
								title: `${t("Do you want to add task with this lead")} ?`,
								buttons: {
									cancle: {
										visible: true,
										text: t("Cancel"),
										value: 0,
										className: "swal-button--danger",
										closeModal: true
									},
									OK: {
										visible: true,
										text: t("OK"),
										value: 1,
										className: "swal-button--success",
										closeModal: true
									}
								},
								dangerMode: true
							}).then((accepted) => {
								if (accepted) {
									setOpenTaskFrm(true);
								} else {
									navigate(`/leads/update/${leadId}`);
								}
							});
						} else {
							dispatch(
								openSnackbar({
									open: true,
									message,
									anchorOrigin: { vertical: "bottom", horizontal: "left" },
									variant: "alert",
									alert: {
										color: "error"
									},
									transition: "Fade",
									close: false
								})
							);
						}
					})
					.catch((err: any) => {
						dispatch(
							openSnackbar({
								open: true,
								message: err && err.data && err.data.exception ? err.data.exception : "",
								anchorOrigin: { vertical: "bottom", horizontal: "left" },
								variant: "alert",
								alert: {
									color: "error"
								},
								transition: "Fade",
								close: false
							})
						);
					});
			}
		};
		const handleCheckAnotherTarget = () => {
			onCloseSelectedTargetTaskDone();
			swal({
				title: `${t("Have you already had the new target")} ?`,
				buttons: {
					cancle: {
						visible: true,
						text: t("not_yet"),
						value: 0,
						className: "swal-button--danger",
						closeModal: true
					},
					OK: {
						visible: true,
						text: t("done_yet"),
						value: 1,
						className: "swal-button--success",
						closeModal: true
					}
				},
				dangerMode: true
			}).then((accepted) => {
				if (accepted) {
					setOpenTargetSidebar(true);
				} else {
					setOpenAddTarget(true);
				}
			});
		};
		const handleDoNotCheckAnyTarget = () => {
			if (leadId && goalId) {
				const leadUpdatedParams: any = {
					step: -1
				};
				axios
					.put(`/lead/update/${leadId}`, leadUpdatedParams, { headers: { isShowLoading: true } })
					.then((leadUpdatedRes: any) => {
						const { status, data, message } = leadUpdatedRes.data;
						if (status) {
							onCloseSelectedTargetTaskDone();
							navigate(`/target-kanban-seller/sale-circle/${goalId}`);
						} else {
							dispatch(
								openSnackbar({
									open: true,
									message,
									anchorOrigin: { vertical: "bottom", horizontal: "left" },
									variant: "alert",
									alert: {
										color: "error"
									},
									transition: "Fade",
									close: false
								})
							);
						}
					})
					.catch((err: any) => {
						dispatch(
							openSnackbar({
								open: true,
								message: err && err.data && err.data.exception ? err.data.exception : "",
								anchorOrigin: { vertical: "bottom", horizontal: "left" },
								variant: "alert",
								alert: {
									color: "error"
								},
								transition: "Fade",
								close: false
							})
						);
					});
			}
		};
		const handleCallback = React.useCallback(() => {
			if (leadId && goalId && step && completedStep) {
				if (nextStepAddTask === "goToKaban") {
					navigate(`/target-kanban-seller/sale-circle/${goalId}`);
				} else {
					const leadUpdatedParams: any = {
						completed_step: completedStep,
						step
					};
					axios
						.put(`/lead/update/${leadId}`, leadUpdatedParams, { headers: { isShowLoading: true } })
						.then((leadUpdatedRes: any) => {
							const { status, data, message } = leadUpdatedRes.data;
							if (status) {
								navigate(`/leads/update/${leadId}`);
							} else {
								dispatch(
									openSnackbar({
										open: true,
										message,
										anchorOrigin: { vertical: "bottom", horizontal: "left" },
										variant: "alert",
										alert: {
											color: "error"
										},
										transition: "Fade",
										close: false
									})
								);
							}
						})
						.catch((err: any) => {
							dispatch(
								openSnackbar({
									open: true,
									message: err && err.data && err.data.exception ? err.data.exception : "",
									anchorOrigin: { vertical: "bottom", horizontal: "left" },
									variant: "alert",
									alert: {
										color: "error"
									},
									transition: "Fade",
									close: false
								})
							);
						});
				}
			}
		}, [nextStepAddTask, leadId, goalId, step, completedStep]);
		const handleCheckedTargetId = React.useCallback(
			(targetIdSelected: number) => {
				if (leadId && goalId && step) {
					let leadUpdateParams: any = {
						rejected: 1,
						step: -1,
						next_target: targetIdSelected,
						is_clear: 2,
						rejected_step: step,
						rejected_type: rejectedType
					};
					axios
						.put(`/lead/update/${leadId}`, leadUpdateParams, { headers: { isShowLoading: true } })
						.then((res: any) => {
							const { status, message } = res.data;
							if (status) {
								setOpenAddTarget(false);
								swal({
									title: `${t("Do you want to add task with this lead")} ?`,
									buttons: {
										cancle: {
											visible: true,
											text: t("Cancel"),
											value: 0,
											className: "swal-button--danger",
											closeModal: true
										},
										OK: {
											visible: true,
											text: t("OK"),
											value: 1,
											className: "swal-button--success",
											closeModal: true
										}
									},
									dangerMode: true
								}).then((accepted) => {
									if (accepted) {
										setNextStepAddTask("goToKaban");
										setOpenTaskFrm(true);
									} else {
										navigate(`/target-kanban-seller/sale-circle/${goalId}`);
									}
								});
							} else {
								dispatch(
									openSnackbar({
										open: true,
										message,
										anchorOrigin: { vertical: "bottom", horizontal: "left" },
										variant: "alert",
										alert: {
											color: "error"
										},
										transition: "Fade",
										close: false
									})
								);
							}
						})
						.catch((err: any) => {
							dispatch(
								openSnackbar({
									open: true,
									message: err && err.data && err.data.exception ? err.data.exception : "",
									anchorOrigin: { vertical: "bottom", horizontal: "left" },
									variant: "alert",
									alert: {
										color: "error"
									},
									transition: "Fade",
									close: false
								})
							);
						});
				}
			},
			[leadId, goalId, step]
		);
		const handleCloseTaskFrm = React.useCallback(() => {
			setOpenTaskFrm(false);
		}, []);
		const handleCloseTargetSidebar = React.useCallback(() => {
			setOpenTargetSidebar(false);
		}, []);
		const handleCloseAddTarget = React.useCallback(() => {
			setOpenAddTarget(false);
		}, []);
		return (
			<React.Fragment>
				<Dialog onClose={onCloseSelectedTargetTaskDone} aria-labelledby="simple-dialog-title" open={isOpenSelectedTargetTaskDone}>
					{isOpenSelectedTargetTaskDone && (
						<React.Fragment>
							<DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
								<Box sx={{ color: theme.palette.primary.dark, textAlign: "center", fontSize: 25, fontWeight: "bold" }}>
									{t("Do you want to continue with this lead")} ?
								</Box>
							</DialogTitle>
							<DialogContent>
								<Box sx={{ boxShadow: 4, p: 1, borderRadius: 2 }}>
									<Button
										sx={{ color: theme.palette.grey[900], fontWeight: "bold", fontSize: 18 }}
										onClick={handleCheckThisTarget}
									>
										{t("Yes, with this target")}
									</Button>
								</Box>
								<Box sx={{ boxShadow: 4, mt: 2, p: 1, borderRadius: 2 }}>
									<Button
										sx={{ color: theme.palette.grey[900], fontWeight: "bold", fontSize: 18 }}
										onClick={handleCheckAnotherTarget}
									>
										{t("Yes, with other target")}
									</Button>
								</Box>
								<Box sx={{ boxShadow: 4, mt: 2, p: 1, borderRadius: 2 }}>
									<Button
										sx={{ color: theme.palette.grey[900], fontWeight: "bold", fontSize: 18 }}
										onClick={handleDoNotCheckAnyTarget}
									>
										{t("No")}
									</Button>
								</Box>
							</DialogContent>
							<DialogActions>
								<Button
									type="button"
									variant="outlined"
									color="error"
									startIcon={<CloseIcon />}
									onClick={onCloseSelectedTargetTaskDone}
								>
									<FormattedMessage id="close" />
								</Button>
							</DialogActions>
						</React.Fragment>
					)}
				</Dialog>
				<TaskFrm isOpenTaskFrm={isOpenTaskFrm} closeTaskFrm={handleCloseTaskFrm} callback={handleCallback} leadId={leadId} />
				<TargetCheckedSidebar
					isOpenTargetSidebar={isOpenTargetSidebar}
					onCloseTargetSidebar={handleCloseTargetSidebar}
					onCheckedTargetId={handleCheckedTargetId}
				/>
				<TargetAddDialog
					isOpenTargetAddDialog={isOpenAddTarget}
					onCloseAddTargetDialog={handleCloseAddTarget}
					onCheckedTargetId={handleCheckedTargetId}
				/>
			</React.Fragment>
		);
	}
);

export default SelectedTargetTaskDone;
