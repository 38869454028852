import React, { forwardRef } from "react";
// material-ui
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	InputAdornment,
	Slide,
	SlideProps,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup
} from "@mui/material";
// project imports
// assets
import CloseIcon from "@mui/icons-material/Close";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/styles";
import { IconSearch } from "@tabler/icons";
import FillUpProduct from "assets/images/product/fill-up-product.png";
import { END_POINT } from "configs";
import { MyOutlinedInputSearch } from "control/MyOutlinedInputSearch";
import useAuth from "hooks/useAuth";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import axios from "utils/axios";
import DataTableLoading from "views/utilities/DataTableLoading";
import { setCookie, getCookie } from "views/utilities/format";
import MyPaginationGlobal from "ui-component/extended/Table/MyPaginationGlobal";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import Chip from "ui-component/extended/Chip";
// animation
const Transition = forwardRef((props: SlideProps, ref) => <Slide direction="left" ref={ref} {...props} />);
interface ITarget {
	id: number;
	name: string;
}
interface IFormInput {
	target_id: number;
}
interface TargetCheckedSidebarProps {
	isOpenTargetSidebar: boolean;
	onCloseTargetSidebar: () => void;
	onCheckedTargetId: (targetIdSelected: number) => void;
}
const PER_PAGE: number = 20;
const NUMBER_ROWS: number[] = [20, 40, 60, 80, 100];
const perPageCookiePrefix = "target_check_sidebar_list_perpage";
const TargetCheckedSidebar: React.FC<TargetCheckedSidebarProps> = React.memo(
	({ isOpenTargetSidebar, onCloseTargetSidebar, onCheckedTargetId }) => {
		const theme = useTheme();
		const { t } = useTranslation();
		const dispatch = useDispatch();
		const { user } = useAuth();
		let mounted: boolean = true;
		const [page, setPage] = React.useState<number>(0);
		const [rowsPerPage, setRowsPerPage] = React.useState<number>(
			getCookie(perPageCookiePrefix) ? parseInt(getCookie(perPageCookiePrefix).toString()) : PER_PAGE
		);
		const [totalItem, setTotalItem] = React.useState<number>(0);
		const [search, setSearch] = React.useState<string>("");
		const [rows, setRows] = React.useState<ITarget[]>([]);
		const getData = (keyword: string, isOpenTargetSidebar: boolean) => {
			if (isOpenTargetSidebar) {
				let params: any = {
					search: keyword ? keyword : undefined,
					seller_id: user && user.id ? parseInt(user.id.toString()) : undefined,
					page: page + 1,
					perpage: getCookie(perPageCookiePrefix) ? parseInt(getCookie(perPageCookiePrefix).toString()) : PER_PAGE
				};
				axios
					.get(`/goals`, {
						params,
						headers: { isShowLoading: false }
					})
					.then((res) => {
						const { status, data } = res.data;
						if (mounted) {
							if (status) {
								let items: ITarget[] = data.items && data.items.length > 0 ? [...data.items] : [];
								setRows(items);
								setTotalItem(data.meta.pagination.total);
							}
						}
					})
					.catch(() => {
						dispatch(
							openSnackbar({
								open: true,
								message: t("error_system"),
								anchorOrigin: { vertical: "bottom", horizontal: "left" },
								variant: "alert",
								alert: {
									color: "error"
								},
								transition: "Fade",
								close: false
							})
						);
					});
			}
		};
		const schema = yup
			.object({
				target_id: yup.number().min(1, t("Please choose at least one target to setup"))
			})
			.required();
		const {
			handleSubmit,
			control,
			setValue,
			formState: { errors }
		} = useForm<IFormInput>({
			defaultValues: {
				target_id: 0
			},
			resolver: yupResolver(schema)
		});
		const onSubmit: SubmitHandler<IFormInput> = async (dataForm) => {
			onCheckedTargetId(dataForm.target_id);
			onCloseTargetSidebar();
		};
		React.useEffect(() => {
			if (isOpenTargetSidebar) {
				setValue("target_id", 0);
				setSearch("");
			}
		}, [isOpenTargetSidebar]);
		React.useEffect(() => {
			getData("", isOpenTargetSidebar);
			return () => {
				mounted = false;
			};
		}, [isOpenTargetSidebar, page, rowsPerPage]);
		const debouncedSearch = React.useRef(
			debounce((keyword: string, isOpenTargetSidebar: boolean) => {
				mounted = true;
				getData(keyword, isOpenTargetSidebar);
			}, END_POINT.DEBOUNCED_TIMEOUT)
		).current;
		const handleSearch = async (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
			let keyword: string = e && e.target && e.target.value ? e.target.value.toString() : "";
			setSearch(keyword);
			setPage(0);

			debouncedSearch(keyword, isOpenTargetSidebar);
		};
		const handleCloseTargetCheckedSidebar = () => {
			onCloseTargetSidebar();
		};
		const handleChangePage = (val: number) => {
			setPage(val);
		};
		const handleChangeRowsPerPage = (val: number) => {
			setCookie(perPageCookiePrefix, val.toString());
			setRowsPerPage(val);
			setPage(0);
		};
		return (
			<Dialog
				open={isOpenTargetSidebar}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleCloseTargetCheckedSidebar}
				sx={{
					"&>div:nth-of-type(3)": {
						justifyContent: "flex-end",
						"&>div": {
							m: 0,
							borderRadius: "0px",
							maxWidth: 450,
							height: "100vh",
							maxHeight: "100%"
						}
					}
				}}
			>
				{isOpenTargetSidebar && (
					<form onSubmit={handleSubmit(onSubmit)}>
						<DialogTitle>
							<FormattedMessage id="target" />
						</DialogTitle>
						<DialogContent>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<MyOutlinedInputSearch
										fullWidth
										placeholder={t("search_list")}
										onChange={handleSearch}
										value={search}
										startAdornment={
											<InputAdornment position="start" sx={{ borderRadius: "4px" }}>
												<IconSearch stroke={1.5} size="16px" />
											</InputAdornment>
										}
										size="small"
									/>
								</Grid>
								<Grid item xs={12}>
									<Controller
										name="target_id"
										control={control}
										render={({ field }) => (
											<FormControl>
												<RadioGroup {...field} sx={{ ml: 1 }}>
													{rows &&
														rows.length > 0 &&
														rows.map((targetElmt: ITarget, targetIdx: number) => {
															return (
																<FormControlLabel
																	key={`target-checked-sidebar-idx-${targetIdx}`}
																	value={targetElmt.id}
																	control={<Radio sx={{ color: theme.palette.primary.dark }} />}
																	label={
																		<Chip
																			label={targetElmt.name}
																			chipcolor={theme.palette.primary.dark}
																			size="small"
																			sx={{ cursor: "pointer" }}
																		/>
																	}
																/>
															);
														})}
												</RadioGroup>
												{errors.target_id && <Box color={theme.palette.error.main}>{errors.target_id.message}</Box>}
											</FormControl>
										)}
									/>
								</Grid>
								<Grid item xs={12}>
									<MyPaginationGlobal
										numberRowsArr={NUMBER_ROWS}
										rowsPerPage={rowsPerPage}
										page={page}
										totalRow={totalItem}
										handleChangePage={handleChangePage}
										handleChangeRowsPerPage={handleChangeRowsPerPage}
									/>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Box display="flex" justifyContent="center" columnGap={1} sx={{ width: "100%" }}>
								{rows && rows.length > 0 && (
									<Button
										variant="contained"
										type="submit"
										startIcon={<SaveOutlinedIcon />}
										sx={{
											background: theme.palette.primary.main,
											"&:hover": {
												background: theme.palette.primary.dark
											}
										}}
									>
										<FormattedMessage id="choose" />
									</Button>
								)}
								<Button
									variant="outlined"
									color="error"
									startIcon={<CloseIcon />}
									onClick={handleCloseTargetCheckedSidebar}
								>
									<FormattedMessage id="close" />
								</Button>
							</Box>
						</DialogActions>
					</form>
				)}
			</Dialog>
		);
	}
);

export default TargetCheckedSidebar;
