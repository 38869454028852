import React from "react";
// material-ui
import { Box, Button, Card, CardContent, Dialog, DialogTitle, Grid, Stack, DialogContent, DialogActions, IconButton } from "@mui/material";
// assets
import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { MyTextField } from "control";
import { MyLabelField } from "control/MyLabelField";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "store";
import AnimateButton from "ui-component/extended/AnimateButton";
import * as yup from "yup";
import { useTheme } from "@mui/styles";
import axios from "utils/axios";
import { openSnackbar } from "store/slices/snackbar";
import produce from "immer";
import TargetFrmCmp from "./TargetFrmCmp";
import { IconX } from "@tabler/icons";
interface TargetAddDialogProps {
	isOpenTargetAddDialog: boolean;
	onCloseAddTargetDialog: () => void;
	onCheckedTargetId: (targetIdSelected: number) => void;
}
const TargetAddDialog: React.FC<TargetAddDialogProps> = ({ isOpenTargetAddDialog, onCloseAddTargetDialog, onCheckedTargetId }) => {
	const theme = useTheme();
	const { t } = useTranslation();
	const handleSetTargetId = (targetId: number) => {
		onCheckedTargetId(targetId);
	};
	const handleCloseAddTargetDialog = () => {
		onCloseAddTargetDialog();
	};
	return (
		<Dialog
			onClose={handleCloseAddTargetDialog}
			aria-labelledby="simple-dialog-title"
			open={isOpenTargetAddDialog}
			fullWidth={true}
			maxWidth="lg"
		>
			{isOpenTargetAddDialog && (
				<React.Fragment>
					<DialogTitle sx={{ position: "relative" }}>
						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							sx={{
								width: 30,
								height: 30,
								background: theme.palette.error.main,
								borderRadius: "50%",
								boxShadow: 1,
								position: "absolute",
								top: 0,
								right: 30
							}}
						>
							<IconButton sx={{ color: "white" }} size="small" onClick={handleCloseAddTargetDialog}>
								<IconX />
							</IconButton>
						</Box>
					</DialogTitle>
					<DialogContent>
						<TargetFrmCmp isOpenTargetAddDialog={isOpenTargetAddDialog} onSetTargetId={handleSetTargetId} />
					</DialogContent>
				</React.Fragment>
			)}
		</Dialog>
	);
};

export default TargetAddDialog;
