import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormattedMessage } from "react-intl";
import HowMeetingDoneApproach from "./HowMeetingDoneApproach";
interface WhatMeetingProps {
	isOpenWhatMeeting: boolean;
	onCloseWhatMeeting: () => void;
	leadId: number;
}
const WhatMeeting: React.FC<WhatMeetingProps> = React.memo(({ isOpenWhatMeeting, onCloseWhatMeeting, leadId }) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const [isOpenHowMeetingDoneApproach, setOpenHowMeetingDoneApproach] = React.useState<boolean>(false);
	const [meetingPrefix, setMeetingPrefix] = React.useState<string>("");
	const handleIntinitialMeeting = () => {
		setOpenHowMeetingDoneApproach(true);
		setMeetingPrefix("initial_meeting");
		onCloseWhatMeeting();
	};
	const handleClosingMeeting = () => {
		setOpenHowMeetingDoneApproach(true);
		setMeetingPrefix("closing_meeting");
		onCloseWhatMeeting();
	};
	const handleTwoInOne = () => {
		setOpenHowMeetingDoneApproach(true);
		setMeetingPrefix("2_in_1");
		onCloseWhatMeeting();
	};
	const handleCloseHowMeetingDoneApproach = React.useCallback(() => {
		setOpenHowMeetingDoneApproach(false);
	}, []);
	return (
		<React.Fragment>
			<Dialog onClose={onCloseWhatMeeting} aria-labelledby="simple-dialog-title" open={isOpenWhatMeeting}>
				{isOpenWhatMeeting && (
					<React.Fragment>
						<DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
							<Box sx={{ color: theme.palette.primary.dark, textAlign: "center", fontSize: 25, fontWeight: "bold" }}>
								{t("What is meeting")} ?
							</Box>
						</DialogTitle>
						<DialogContent>
							<Box sx={{ boxShadow: 4, p: 1, borderRadius: 2 }}>
								<Button
									sx={{ color: theme.palette.grey[900], fontWeight: "bold", fontSize: 18 }}
									onClick={handleIntinitialMeeting}
								>
									{t("Intinitial Meetings")}
								</Button>
							</Box>
							<Box sx={{ boxShadow: 4, mt: 2, p: 1, borderRadius: 2 }}>
								<Button
									sx={{ color: theme.palette.grey[900], fontWeight: "bold", fontSize: 18 }}
									onClick={handleClosingMeeting}
								>
									{t("Closing Meetings")}
								</Button>
							</Box>
							<Box sx={{ boxShadow: 4, mt: 2, p: 1, borderRadius: 2 }}>
								<Button sx={{ color: theme.palette.grey[900], fontWeight: "bold", fontSize: 18 }} onClick={handleTwoInOne}>
									{t("2 in 1")}
								</Button>
							</Box>
						</DialogContent>
						<DialogActions>
							<Button type="button" variant="outlined" color="error" startIcon={<CloseIcon />} onClick={onCloseWhatMeeting}>
								<FormattedMessage id="close" />
							</Button>
						</DialogActions>
					</React.Fragment>
				)}
			</Dialog>
			<HowMeetingDoneApproach
				isOpenHowMeetingDoneApproach={isOpenHowMeetingDoneApproach}
				onCloseHowMeetingDoneApproach={handleCloseHowMeetingDoneApproach}
				meetingPrefix={meetingPrefix}
				leadId={leadId}
			/>
		</React.Fragment>
	);
});

export default WhatMeeting;
