import React from "react";

// material-ui
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
// assets
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/styles";
import { END_POINT } from "configs";
import { useTranslation } from "react-i18next";
import { useDispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import axios from "utils/axios";
import TaskFrm from "./TaskFrm";
interface ChangeDeadlineConfirmedDialogProps {
	onCloseChangeDeadlineConfirmedDialog: () => void;
	isOpenChangeDeadlineConfirmedDialog: boolean;
	taskId: number;
	notiId: string;
	loadNotificationLst?: () => void;
}
const ChangeDeadlineConfirmedDialog: React.FC<ChangeDeadlineConfirmedDialogProps> = React.memo(
	({ onCloseChangeDeadlineConfirmedDialog, isOpenChangeDeadlineConfirmedDialog, taskId, notiId, loadNotificationLst }) => {
		const { t } = useTranslation();
		const theme = useTheme();
		const dispatch = useDispatch();
		const [isOpenTaskFrm, setOpenTaskFrm] = React.useState<boolean>(false);
		const handleConfirmedNo = () => {
			axios
				.delete(END_POINT.API_DELETE_NOTI, { params: { id: notiId } })
				.then((res: any) => {
					const { status, message } = res.data;
					if (status) {
						onCloseChangeDeadlineConfirmedDialog();
						if (loadNotificationLst) {
							loadNotificationLst();
						}
						dispatch(
							openSnackbar({
								open: true,
								message: t("task_is_change_to_overdue"),
								anchorOrigin: { vertical: "bottom", horizontal: "left" },
								variant: "alert",
								alert: {
									color: "success"
								},
								transition: "Fade",
								close: false
							})
						);
					} else {
						dispatch(
							openSnackbar({
								open: true,
								message,
								anchorOrigin: { vertical: "bottom", horizontal: "left" },
								variant: "alert",
								alert: {
									color: "error"
								},
								transition: "Fade",
								close: false
							})
						);
					}
				})
				.catch((err: any) => {
					dispatch(
						openSnackbar({
							open: true,
							message: err && err.data && err.data.exception ? err.data.exception : "",
							anchorOrigin: { vertical: "bottom", horizontal: "left" },
							variant: "alert",
							alert: {
								color: "erro"
							},
							transition: "Fade",
							close: false
						})
					);
				});
		};
		const handleConfirmedYes = () => {
			if (taskId) {
				setOpenTaskFrm(true);
				onCloseChangeDeadlineConfirmedDialog();
				if (loadNotificationLst) {
					loadNotificationLst();
				}
			}
		};
		const handleCloseTaskFrm = React.useCallback(() => {
			setOpenTaskFrm(false);
		}, []);
		return (
			<React.Fragment>
				<Dialog
					onClose={onCloseChangeDeadlineConfirmedDialog}
					aria-labelledby="simple-dialog-title"
					open={isOpenChangeDeadlineConfirmedDialog}
				>
					{isOpenChangeDeadlineConfirmedDialog && (
						<React.Fragment>
							<DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
								<Box sx={{ color: theme.palette.primary.dark, textAlign: "center", fontSize: 25, fontWeight: "bold" }}>
									{t("Task overdue")} ?
								</Box>
							</DialogTitle>
							<DialogContent>
								<Box sx={{ fontWeight: "bold", color: theme.palette.grey[900] }}>
									{t("This task will be overdue now. Do you want to change the deadline")} ?
								</Box>
							</DialogContent>
							<DialogActions>
								<Button
									variant="contained"
									type="button"
									sx={{
										bgcolor: "primary.dark",
										"&:hover": {
											bgcolor: "primary.main"
										}
									}}
									onClick={handleConfirmedYes}
								>
									{t("Yes")}
								</Button>
								<Button type="button" onClick={handleConfirmedNo} variant="contained" color="error">
									{t("No")}
								</Button>
								<Button
									type="button"
									variant="outlined"
									color="error"
									startIcon={<CloseIcon />}
									onClick={onCloseChangeDeadlineConfirmedDialog}
								>
									{t("close")}
								</Button>
							</DialogActions>
						</React.Fragment>
					)}
				</Dialog>
				<TaskFrm
					isOpenTaskFrm={isOpenTaskFrm}
					closeTaskFrm={handleCloseTaskFrm}
					notificationId={notiId}
					loadNotificationLst={loadNotificationLst}
					taskId={taskId}
				/>
			</React.Fragment>
		);
	}
);
export default ChangeDeadlineConfirmedDialog;
