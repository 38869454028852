import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultRootStateProps } from 'types';
import { NotifyStateProps } from 'types/notify';

const initialState: any = {
    id: 0,
    script: '',
    twelveStepData: null,
    saleStatus: '',
    controlStep: 8,
    activeStep: 0,
    dealData: null,
    backDrop: false,
};
const slice = createSlice({
    name: 'saleCycle',
    initialState,
    reducers: {
        updateCurrentLeadId: (state: any, action: PayloadAction<number>) => {
            state.id = action.payload;
        },
        updateScript: (state: any, action: PayloadAction<string>) => {
            state.script = action.payload;
        },
        updateStep: (state: any, action: PayloadAction<number>) => {
            state.activeStep = action.payload;
        },
        updateSaleStatus: (state: any, action: PayloadAction<string>) => {
            state.saleStatus = action.payload;
        },
        updateControlStep: (state: any, action: PayloadAction<number>) => {
            state.controlStep = action.payload;
        },
        updateTwelveStepData: (state: any, action: PayloadAction<any>) => {
            state.twelveStepData = action.payload;
        },
        updateDealData: (state: any, action: PayloadAction<any>) => {
            state.dealData = action.payload;
        },
        updateBackDrop:(state: any, action: PayloadAction<boolean>) => {
            state.backDrop = action.payload;
        },
    },
});
export default slice.reducer;
export const { updateScript, updateStep, updateSaleStatus, updateControlStep, updateTwelveStepData, updateCurrentLeadId,updateBackDrop } = slice.actions;

const node = (state: any) => state.saleCycle;
export const getLeadId = createSelector(node, (state) => state.id);
export const getScript = createSelector(node, (state) => state.script);
export const twelveStepData = createSelector(node, (state) => state.twelveStepData);
export const getActiveStep = createSelector(node, (state) => state.activeStep);
export const getSaleStatus = createSelector(node, (state) => state.saleStatus);
export const getControlStep = createSelector(node, (state) => state.controlStep);
export const getDealData = createSelector(node, (state) => state.dealData);
export const getBackDrop = createSelector(node, (state) => state.backDrop);
