import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddIcon from "@mui/icons-material/AddTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import React, { forwardRef } from "react";
import Chip from "ui-component/extended/Chip";
// material-ui
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	FormHelperText,
	Grid,
	IconButton,
	MenuItem,
	Radio,
	RadioGroup,
	SelectChangeEvent,
	Slide,
	SlideProps,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from "@mui/material";
// project imports
import AnimateButton from "ui-component/extended/AnimateButton";
import axios from "utils/axios";
// assets
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { IconX } from "@tabler/icons";
import { END_POINT } from "configs";
import { MySelectField, MyTextField } from "control";
import { MyLabelField } from "control/MyLabelField";
import { useFormik } from "formik";
import useConfig from "hooks/useConfig";
import { debounce } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import { ILeadAutoComplete, IReminderSelectedField, ITargetAutoComplete, ITask } from "types/i-task";
import * as yup from "yup";
// animation
const Transition = forwardRef((props: SlideProps, ref) => <Slide direction="left" ref={ref} {...props} />);
interface IMakePoint {
	lead_id: number;
	point_type: string;
	point_type_sub_first?: string;
	point_type_sub_second?: string;
}
// ==============================|| PRODUCT ADD DIALOG ||============================== //
interface ITaskSaved {
	title: string | null;
	type: string | null;
	task_perform: string | null;
	description: string | null;
	reminder_time_type: number | null;
	status: string | null;
	start_date: string | null;
	end_date: string | null;
	goal_id: number | null;
	lead_id?: number | null;
	lead_ids?: number[] | null;
	step: number | null;
	in_day: boolean;
}
interface ITaskSelectedByDate {
	title: string;
	startTime: string;
	endTime: string;
}
const TaskFrm: React.FC<{
	isOpenTaskFrm: boolean;
	closeTaskFrm: () => void;
	typeTask?: string;
	leadId?: number;
	lead_ids_selected?: number[];
	point_type?: string;
	point_type_sub_first?: string;
	point_type_sub_second?: string;
	notificationId?: string;
	loadNotificationLst?: () => void;
	callback?: () => void;
	taskId?: number;
	onReload?: () => void;
}> = React.memo(
	({
		isOpenTaskFrm,
		closeTaskFrm,
		typeTask,
		leadId,
		lead_ids_selected,
		point_type,
		point_type_sub_first,
		point_type_sub_second,
		notificationId,
		loadNotificationLst,
		callback,
		taskId,
		onReload
	}) => {
		const { t } = useTranslation();
		const theme = useTheme();
		const { dateFormat } = useConfig();
		const dispatch = useDispatch();
		let mounted: boolean = true;
		const [targetLst, setTargetLst] = React.useState<ITargetAutoComplete[]>([
			{
				id: 0,
				name: t("none"),
				leads: []
			}
		]);
		const [leadLst, setLeadLst] = React.useState<ILeadAutoComplete[]>([
			{
				id: 0,
				name: t("none")
			}
		]);
		const [reminderLst, setReminderLst] = React.useState<IReminderSelectedField[]>([
			{
				type: 0,
				name: t("none")
			}
		]);
		const [startDateVal, setStartDate] = React.useState<Date | null>(new Date());
		const [leadNamesSelected, setLeadNamesSelected] = React.useState<string[]>([]);
		const [isOpenListLeadItem, setOpenListLeadItem] = React.useState<boolean>(false);
		const [taskByDateSelected, setTaskByDateSelected] = React.useState<ITaskSelectedByDate[]>([]);
		const [stepNumber, setStepNumber] = React.useState<number | null>(null);
		const validationSchema = yup.object({
			title: yup.string().required(t("field_required")),
			date_start: yup.string().required(t("field_required"))
		});
		const formik = useFormik({
			initialValues: {
				title: "",
				goal_id: 0,
				lead_id: 0,
				task_type: typeTask ? typeTask : "task",
				task_perform: "call",
				description: "",
				reminder_time_type: 0,
				in_day: false,
				start_time: new Date().getHours().toString().padStart(2, "0") + ":" + new Date().getMinutes().toString().padStart(2, "0"),
				end_time:
					new Date().getHours().toString().padStart(2, "0") + ":" + (new Date().getMinutes() + 1).toString().padStart(2, "0"),
				lead_name: "",
				date_start:
					new Date().getFullYear().toString() +
					"-" +
					(new Date().getMonth() + 1).toString().padStart(2, "0") +
					"-" +
					new Date().getDate().toString().padStart(2, "0")
			},
			validationSchema,
			onSubmit: async (values) => {
				let checked = true;
				let txtMsg = "";
				let typeMsg = "error";
				let actionUrl = "";
				let res: any = null;
				let dataSaved: ITaskSaved | null = null;
				let start_date: string | null = null;
				let end_date: string | null = null;
				let txtStartTime = "00:00";
				let txtEndTime = "23:59";
				let startDateMilisecond: number = 0;
				let endDateMilisecond: number = 0;
				let task_perform: string | null = null;
				let txtYear: string = "";
				let txtMonth: string = "";
				let txtDay: string = "";
				let statusCompleted: string = "un_completed";
				let currentDateMilisecond = new Date().getTime();
				try {
					if (startDateVal) {
						txtYear = startDateVal.getFullYear().toString();
						txtMonth = (startDateVal.getMonth() + 1).toString().padStart(2, "0");
						txtDay = startDateVal.getDate().toString().padStart(2, "0");
						if (formik.values.in_day === false) {
							txtStartTime = formik.values.start_time;
							txtEndTime = formik.values.end_time;
						}
						start_date = txtYear + "-" + txtMonth + "-" + txtDay + " " + txtStartTime + ":" + "00";
						end_date = txtYear + "-" + txtMonth + "-" + txtDay + " " + txtEndTime + ":" + "00";
						startDateMilisecond = Date.parse(start_date);
						endDateMilisecond = Date.parse(end_date);
					} else {
						checked = false;
						txtMsg = t("start_date_field_required");
					}
					if (formik.values.in_day === false || formik.values.task_type.includes("meeting")) {
						if (startDateMilisecond >= endDateMilisecond) {
							checked = false;
							txtMsg = t("invalid_task_start_end_date");
						}
					}
					if (checked) {
						if (values.task_type && values.task_type.includes("task") && values.task_perform) {
							task_perform = values.task_perform;
						}
						if (currentDateMilisecond >= endDateMilisecond) {
							statusCompleted = "is_overdue";
						}
						let lead_ids: number[] = [];
						if (leadNamesSelected && leadNamesSelected.length > 0) {
							leadNamesSelected.forEach((item) => {
								let leadItem: ILeadAutoComplete | undefined = leadLst.find(
									(elmt) => elmt.name.toString().trim().toLowerCase() === item.toString().trim().toLowerCase()
								);
								if (leadItem && leadItem.id > 0) {
									lead_ids.push(leadItem.id);
								}
							});
						}
						if (lead_ids_selected && lead_ids_selected.length > 0) {
							lead_ids = lead_ids_selected;
						}
						dataSaved = {
							title: values.title ? values.title.trim() : null,
							goal_id: values.goal_id ? values.goal_id : null,
							start_date,
							end_date,
							in_day: values.in_day,
							type: values.task_type ? values.task_type.trim() : null,
							task_perform,
							description: values.description ? values.description.trim() : null,
							reminder_time_type: values.reminder_time_type ? parseInt(values.reminder_time_type.toString().trim()) : null,
							step: stepNumber !== null ? stepNumber : null,
							status: statusCompleted
						};
						if (taskId && !leadId) {
							dataSaved = { ...dataSaved, lead_id: values.lead_id ? values.lead_id : null };
							actionUrl = `${END_POINT.API_TASK_UPDATE}/${taskId}`;
							res = await axios.put(actionUrl, dataSaved, { headers: { isShowLoading: true } });
						} else {
							dataSaved = { ...dataSaved, lead_ids: lead_ids && lead_ids.length > 0 ? lead_ids : null };
							actionUrl = END_POINT.API_TASK_CREATE;
							res = await axios.post(actionUrl, dataSaved, { headers: { isShowLoading: true } });
						}
						const { status, message, data } = res.data;
						txtMsg = message;
						if (status) {
							typeMsg = "success";
							txtMsg = t("save_task_successfully");
							if (!leadId && onReload) {
								onReload();
							}
							if (callback) {
								callback();
							}
							if (point_type && leadId) {
								let dataSaved: IMakePoint | null = null;
								switch (point_type) {
									case "make_call":
										dataSaved = {
											lead_id: leadId,
											point_type,
											point_type_sub_first
										};
										break;
									case "meeting":
										dataSaved = {
											lead_id: leadId,
											point_type,
											point_type_sub_first,
											point_type_sub_second
										};
										break;
								}
								const resMakePoint: any = await axios.post(END_POINT.API_LEAD_MAKE_POINT, dataSaved);
							}
							if (notificationId) {
								const rsDeleteNoti: any = await axios.delete(END_POINT.API_DELETE_NOTI, {
									params: { id: notificationId }
								});
								if (loadNotificationLst) {
									loadNotificationLst();
								}
							}
						} else {
							txtMsg = t("save_task_failure");
						}
						closeTaskFrm();
					}
				} catch (err: any) {
					txtMsg = err && err.data && err.data.exception ? err.data.exception : "";
				}
				dispatch(
					openSnackbar({
						open: true,
						message: txtMsg,
						anchorOrigin: { vertical: "bottom", horizontal: "left" },
						variant: "alert",
						alert: {
							color: typeMsg
						},
						transition: "Fade",
						close: false
					})
				);
			}
		});
		const handleChangeTaskType = (e: SelectChangeEvent<unknown>) => {
			let title = "";
			let val: any = e && e.target && e.target.value ? e.target.value : "meeting";
			let task_perform = formik.values.task_perform.toString().trim();
			switch (val) {
				case "meeting":
					title = t("meet");
					break;
				case "task":
					switch (task_perform) {
						case "call":
							title = t("call");
							break;
						case "message":
							title = t("message");
							break;
						default:
							title = t("Task");
							break;
					}
					break;
			}
			formik.setFieldValue("title", title);
			formik.setFieldValue("task_type", val);
			doTaskSelected(startDateVal, val);
		};
		const doTaskSelected = async (newDate: Date | null, taskType: string) => {
			if (isOpenTaskFrm && newDate) {
				const dateText: string =
					newDate.getFullYear().toString() +
					"-" +
					(newDate.getMonth() + 1).toString().padStart(2, "0") +
					"-" +
					newDate.getDate().toString().padStart(2, "0");
				const res = await axios.get("/task/get-list-task-today-or-another-date", {
					params: { date: dateText, type: taskType }
				});
				const { status, data } = res.data;
				if (status && mounted) {
					let items: ITask[] = data.items ? data.items : [];
					let taskSelectedRows: ITaskSelectedByDate[] = [];
					if (items.length > 0) {
						taskSelectedRows = items.map((item, idx) => {
							let startTime = "";
							let endTime = "";
							if (item.start_date) {
								let valDate: Date = new Date(moment(item.start_date).format());
								startTime =
									valDate.getHours().toString().padStart(2, "0") + ":" + valDate.getMinutes().toString().padStart(2, "0");
							}
							if (item.end_date) {
								let valDate: Date = new Date(moment(item.end_date).format());
								endTime =
									valDate.getHours().toString().padStart(2, "0") + ":" + valDate.getMinutes().toString().padStart(2, "0");
							}
							return { title: item.title ? item.title : "", startTime, endTime };
						});
					}
					setTaskByDateSelected(taskSelectedRows);
				}
			}
		};
		const handleChangeTaskPerform = (e: SelectChangeEvent<unknown>) => {
			let title = "";
			let val = e && e.target && e.target.value ? e.target.value : "call";
			switch (val) {
				case "call":
					title = t("call");
					break;
				case "message":
					title = t("message");
					break;
				default:
					title = t("Task");
					break;
			}
			formik.setFieldValue("title", title);
			formik.setFieldValue("task_perform", val);
		};
		const handleChangeGoalId = (e: SelectChangeEvent<unknown>) => {
			let target_id: number = e.target.value as number;
			let targetFounded: ITargetAutoComplete | undefined = targetLst.find((elmt) => elmt.id === target_id);
			let leads: ILeadAutoComplete[] = [];
			if (targetFounded && targetFounded.leads && targetFounded.leads.length > 0) {
				leads = targetFounded.leads.map((elmt) => {
					return { id: elmt.id, name: elmt.name ? elmt.name : "" };
				});
			}
			leads.unshift({ id: 0, name: t("none") });
			setLeadLst(leads);
			setLeadNamesSelected([]);
			formik.setFieldValue("goal_id", target_id);
			formik.setFieldValue("lead_id", 0);
		};
		React.useEffect(() => {
			doTaskSelected(new Date(), "task");
			return () => {
				mounted = false;
			};
		}, [isOpenTaskFrm]);
		React.useEffect(() => {
			const init = async () => {
				if (isOpenTaskFrm) {
					let promise1: any = null;
					if (leadId) {
						promise1 = axios.get(`/lead/show/${leadId}`);
					}
					const promise2: any = axios.get("/goal/list-without-paginate");
					const promise3: any = axios.get("/task/get-reminders");
					const [resLeadDetail, resGoal, resReminder] = await Promise.all([promise1, promise2, promise3]);
					let leadName: string = "";
					let goalId: number = 0;
					let goalName: string = "";
					let stepNumber: number | null = null;
					if (promise1) {
						if (resLeadDetail && resLeadDetail.data && resLeadDetail.data.data && resLeadDetail.data.data.items) {
							leadName = resLeadDetail.data.data.items.name;
							goalId = parseInt(resLeadDetail.data.data.items.goal.id);
							goalName = resLeadDetail.data.data.items.goal.name;
							stepNumber = resLeadDetail.data.data.items.step ? parseInt(resLeadDetail.data.data.items.step) : null;
						}
					}
					let title: string = "";
					if (formik.values.task_type && formik.values.task_type === "meeting" && leadId) {
						title = "Meet " + (leadName ? leadName : t("none"));
					}
					let taskType: string = typeTask ? typeTask : "task";
					let taskPerform: string = "call";
					let startDate: Date = new Date();
					let checkInDay: boolean = false;
					let startTime: string =
						new Date().getHours().toString().padStart(2, "0") + ":" + new Date().getMinutes().toString().padStart(2, "0");
					let endTime: string =
						new Date().getHours().toString().padStart(2, "0") + ":" + new Date().getMinutes().toString().padStart(2, "0");
					let namesSelected: string[] = [];
					let leadRows: ILeadAutoComplete[] = [];
					let reminderItems: IReminderSelectedField[] = [];
					let targetItems: ITargetAutoComplete[] = [];
					let description: string = "";
					let reminderTimeType: number = 2;
					if (leadId && leadName) {
						namesSelected.push(leadName);
						leadRows.push({ id: leadId, name: leadName });
					}
					/* begin set TargetItems */
					if (leadId && goalId) {
						targetItems.push({
							id: goalId,
							name: goalName ? goalName.toString() : "",
							leads: [{ id: leadId, name: leadName ? leadName : "" }]
						});
					} else {
						if (resGoal && resGoal.data && resGoal.data.data && resGoal.data.data.items) {
							targetItems = resGoal.data.data.items.length > 0 ? resGoal.data.data.items : [];
						}
					}
					targetItems.unshift({
						id: 0,
						name: t("none"),
						leads: []
					});
					/* end set TargetItems */
					if (resReminder && resReminder.data && resReminder.data.data) {
						reminderItems = resReminder.data.data.length > 0 ? resReminder.data.data : [];
					}
					reminderItems.push({
						type: 0,
						name: t("none")
					});
					if (taskId) {
						const rsTaskDetail: any = await axios.get(`/task/show/${taskId}`, { headers: { isShowLoading: true } });
						const { status, data } = rsTaskDetail.data;
						if (status) {
							let taskElmt: ITask | null = data.items ? data.items : null;
							if (taskElmt) {
								if (taskElmt.title) {
									title = taskElmt.title.toString().trim();
								}
								if (taskElmt.type) {
									taskType = taskElmt.type.toString().trim();
								}
								if (taskElmt.task_perform) {
									taskPerform = taskElmt.task_perform.toString().trim();
								}
								if (taskElmt.start_date) {
									startDate = new Date(moment(taskElmt.start_date.toString().trim()).format());
								}
								if (taskElmt.in_day) {
									checkInDay = taskElmt.in_day;
								}
								if (taskElmt.in_day === false && taskElmt.start_date && taskElmt.end_date) {
									let startMoment: string = moment(taskElmt.start_date.toString().trim()).format();
									let endMoment: string = moment(taskElmt.end_date.toString().trim()).format();
									startTime =
										new Date(startMoment).getHours().toString().padStart(2, "0") +
										":" +
										new Date(startMoment).getMinutes().toString().padStart(2, "0");
									endTime =
										new Date(endMoment).getHours().toString().padStart(2, "0") +
										":" +
										new Date(endMoment).getMinutes().toString().padStart(2, "0");
								}
								if (taskElmt.goal && taskElmt.goal.id) {
									goalId = taskElmt.goal.id;
								} else {
									goalId = taskElmt.lead && taskElmt.lead.goal_id ? taskElmt.lead.goal_id : 0;
								}
								if (targetItems.length > 0) {
									let targetFounded: ITargetAutoComplete | undefined = targetItems.find((elmt) => elmt.id === goalId);
									if (targetFounded && targetFounded.leads && targetFounded.leads.length > 0) {
										leadRows = targetFounded.leads.map((elmt) => {
											return { id: elmt.id, name: elmt.name ? elmt.name : "" };
										});
									}
								}
								leadRows.unshift({ id: 0, name: t("none") });
								if (taskElmt.lead && taskElmt.lead.id) {
									leadId = taskElmt.lead.id;
								}
								if (taskElmt.description) {
									description = taskElmt.description.toString().trim();
								}
								if (reminderItems && reminderItems.length > 0) {
									reminderTimeType = taskElmt.reminder_time_type ? taskElmt.reminder_time_type : 0;
								}
							}
						}
					}
					if (mounted) {
						setStepNumber(stepNumber);
						setLeadNamesSelected(namesSelected);
						setLeadLst(leadRows);
						setTargetLst(targetItems);
						setReminderLst(reminderItems);
						setStartDate(startDate);
						formik.setFieldValue("title", title);
						formik.setFieldValue("task_type", taskType);
						formik.setFieldValue("task_perform", taskPerform);
						formik.setFieldValue("in_day", checkInDay);
						formik.setFieldValue("start_time", startTime);
						formik.setFieldValue("end_time", endTime);
						formik.setFieldValue("goal_id", goalId ? goalId : 0);
						formik.setFieldValue("lead_id", leadId ? leadId : 0);
						formik.setFieldValue("description", description);
						formik.setFieldValue("reminder_time_type", reminderTimeType);
					}
				}
			};
			init();
			return () => {
				mounted = false;
			};
		}, [isOpenTaskFrm, leadId, taskId]);
		const handleChangeStartTime = (e: React.ChangeEvent<HTMLInputElement>) => {
			formik.setFieldValue("start_time", e.target.value);
		};
		const handleChangeEndTime = (e: React.ChangeEvent<HTMLInputElement>) => {
			formik.setFieldValue("end_time", e.target.value);
		};
		/* begin Multiple Autocomplete Lead */
		const handleLeadSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, val: string) => {
			let nextState: string[] = [...leadNamesSelected];
			if (nextState.indexOf(val) === -1) {
				nextState.push(val);
				formik.setFieldValue("lead_name", "");
				setLeadNamesSelected(nextState);
			}
		};
		const handleLeadNameMouseDown = () => {
			let targetId: number = formik.values.goal_id;
			let targetFounded: ITargetAutoComplete | undefined = targetLst.find((elmt) => elmt.id === targetId);
			let leads: ILeadAutoComplete[] = [];
			if (targetFounded && targetFounded.leads && targetFounded.leads.length > 0) {
				leads = targetFounded.leads.map((elmt) => {
					return { id: elmt.id, name: elmt.name ? elmt.name : "" };
				});
			}
			leads.unshift({ id: 0, name: t("none") });
			setLeadLst(leads);
			setOpenListLeadItem(true);
		};
		const handleLeadNameChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
			let keyword: string = e && e.target && e.target.value ? e.target.value.toString() : "";
			let targetId: number = formik.values.goal_id;
			formik.setFieldValue("lead_name", keyword);
			debouncedSearch(keyword, targetId);
		};
		const debouncedSearch = React.useRef(
			debounce(async (search: string, target_id: number) => {
				const res: any = await axios.get(END_POINT.API_LEAD_DROPDOWNLIST, { params: { search, target_id } });
				const { status, data } = res.data;
				if (status) {
					let leadData: ILeadAutoComplete[] = data.items && data.items.length > 0 ? data.items : [];
					setLeadLst(leadData);
				}
			}, END_POINT.DEBOUNCED_TIMEOUT)
		).current;
		React.useEffect(() => {
			return () => {
				debouncedSearch.cancel();
			};
		}, [debouncedSearch]);
		const handleDeleteChipLeadName = (idx: number) => () => {
			let leadNames: string[] = [...leadNamesSelected];
			leadNames.splice(idx, 1);
			setLeadNamesSelected(leadNames);
		};
		const handleCloseListLeadName = () => {
			setOpenListLeadItem(false);
			formik.setFieldValue("lead_name", "");
		};
		/* end Multiple Autocomplete Lead */

		const handleChangeStartDate = (newVal: Date | null) => {
			doTaskSelected(newVal, formik.values.task_type);
			setStartDate(newVal);
		};
		return (
			<Dialog
				open={isOpenTaskFrm}
				TransitionComponent={Transition}
				keepMounted
				onClose={closeTaskFrm}
				sx={{
					"&>div:nth-of-type(3)": {
						justifyContent: "flex-end",
						"&>div": {
							m: 0,
							borderRadius: "0px",
							maxWidth: 450,
							height: "100vh",
							maxHeight: "100%"
						}
					}
				}}
			>
				{isOpenTaskFrm && (
					<form onSubmit={formik.handleSubmit}>
						<DialogTitle>
							{taskId && !leadId ? <FormattedMessage id="update_task" /> : <FormattedMessage id="add_new_task" />}
						</DialogTitle>
						<DialogContent>
							<Grid container spacing={3} sx={{ mt: 0.25 }}>
								<Grid item xs={12}>
									<Stack>
										<MyLabelField required>
											<FormattedMessage id="title" />
										</MyLabelField>
										<MyTextField
											size="small"
											name="title"
											value={formik.values.title}
											onBlur={formik.handleBlur}
											error={formik.touched.title && Boolean(formik.errors.title)}
											helperText={formik.touched.title && formik.errors.title}
											onChange={formik.handleChange}
											fullWidth
											placeholder={t("title")}
											onMouseDown={handleCloseListLeadName}
										/>
									</Stack>
								</Grid>
								<Grid item xs={12}>
									<Stack>
										<MyLabelField>
											<FormattedMessage id="task_type" />
										</MyLabelField>
										<Stack direction="row" spacing={2} justifyContent="space-around">
											<RadioGroup
												row
												aria-label="task_type"
												value={formik.values.task_type}
												onChange={handleChangeTaskType}
												name="task_type"
												onMouseDown={handleCloseListLeadName}
											>
												<FormControlLabel
													value="meeting"
													control={
														<Radio
															sx={{
																color: "primary.main",
																"&.Mui-checked": {
																	color: "primary.main"
																}
															}}
														/>
													}
													label={t("meeting")}
												/>
												<FormControlLabel
													value="task"
													control={
														<Radio
															sx={{
																color: "error.main",
																"&.Mui-checked": {
																	color: "error.main"
																}
															}}
														/>
													}
													label={t("task")}
												/>
											</RadioGroup>
										</Stack>
									</Stack>
								</Grid>
								{formik.values.task_type.includes("task") && (
									<Grid item xs={12}>
										<Stack direction="row" justifyContent="center">
											<RadioGroup
												row
												aria-label="task_perform"
												value={formik.values.task_perform}
												onChange={handleChangeTaskPerform}
												name="task_perform"
												defaultValue="call"
												onMouseDown={handleCloseListLeadName}
											>
												<FormControlLabel
													value="call"
													control={
														<Radio
															sx={{
																color: "primary.dark",
																"&.Mui-checked": { color: "primary.dark" }
															}}
														/>
													}
													label={t("call")}
												/>
												<FormControlLabel
													value="message"
													control={
														<Radio
															sx={{
																color: "error.main",
																"&.Mui-checked": { color: "error.main" }
															}}
														/>
													}
													label={t("message")}
												/>
												<FormControlLabel
													value="others"
													control={
														<Radio
															sx={{
																color: "secondary.main",
																"&.Mui-checked": {
																	color: "secondary.main"
																}
															}}
														/>
													}
													label={t("others")}
												/>
											</RadioGroup>
										</Stack>
									</Grid>
								)}
								<Grid item xs={12}>
									<Stack direction="row" spacing={1} alignItems="center">
										<Stack sx={{ height: "100%" }} justifyContent="center" alignItems="center">
											<AccessTimeIcon />
										</Stack>
										<Stack>
											<Grid container spacing={1}>
												<Grid item xs={6}>
													<LocalizationProvider dateAdapter={AdapterDateFns}>
														<DatePicker
															value={startDateVal}
															onChange={handleChangeStartDate}
															renderInput={(props) => <MyTextField size="small" fullWidth {...props} />}
															inputFormat={dateFormat}
															mask="__/__/____"
														/>
													</LocalizationProvider>
												</Grid>
												<Grid item xs={6}>
													{formik.values.task_type && !formik.values.task_type.includes("meeting") && (
														<FormControlLabel
															control={
																<Checkbox
																	name="in_day"
																	onChange={formik.handleChange}
																	checked={formik.values.in_day}
																	onMouseDown={handleCloseListLeadName}
																/>
															}
															label={t("in_day")}
														/>
													)}
												</Grid>
												{formik.values.in_day === false || formik.values.task_type.includes("meeting") ? (
													<Grid item xs={12}>
														<Box display="flex" justifyContent="flex-start" columnGap={1} alignItems="center">
															<MyTextField
																size="small"
																type="time"
																name="start_time"
																value={formik.values.start_time}
																onChange={handleChangeStartTime}
																onMouseDown={handleCloseListLeadName}
																InputLabelProps={{
																	shrink: true
																}}
															/>
															<Box
																sx={{
																	width: 8,
																	height: 2,
																	background: theme.palette.grey[900]
																}}
															></Box>
															<MyTextField
																size="small"
																type="time"
																name="end_time"
																value={formik.values.end_time}
																onChange={handleChangeEndTime}
																onMouseDown={handleCloseListLeadName}
																InputLabelProps={{
																	shrink: true
																}}
															/>
														</Box>
													</Grid>
												) : (
													<React.Fragment></React.Fragment>
												)}
											</Grid>
										</Stack>
									</Stack>
								</Grid>
								{taskByDateSelected && taskByDateSelected.length > 0 && (
									<Grid item xs={12}>
										<TableContainer>
											<Table>
												<TableHead>
													<TableRow>
														<TableCell>{t("task_today_title")}</TableCell>
														<TableCell>{t("selected_time")}</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{taskByDateSelected.map((elmt, idx) => {
														return (
															<TableRow key={`task-by-select-item-${idx}`}>
																<TableCell>{elmt.title}</TableCell>
																<TableCell>{`${elmt.startTime} - ${elmt.endTime}`}</TableCell>
															</TableRow>
														);
													})}
												</TableBody>
											</Table>
										</TableContainer>
									</Grid>
								)}

								{lead_ids_selected === undefined || lead_ids_selected === null ? (
									<Grid item xs={12}>
										<Stack>
											<MyLabelField>
												<FormattedMessage id="target" />
											</MyLabelField>
											<MySelectField
												size="small"
												name="goal_id"
												value={formik.values.goal_id}
												onChange={handleChangeGoalId}
												onMouseDown={handleCloseListLeadName}
											>
												{targetLst &&
													targetLst.map((elmt) => {
														return (
															<MenuItem key={`task-frm-goal-id-${elmt.id}`} value={elmt.id}>
																{elmt.name}
															</MenuItem>
														);
													})}
											</MySelectField>
											{formik.errors.goal_id && <FormHelperText error> {formik.errors.goal_id} </FormHelperText>}
										</Stack>
									</Grid>
								) : (
									<React.Fragment></React.Fragment>
								)}
								{lead_ids_selected === undefined || lead_ids_selected === null ? (
									<Grid item xs={12}>
										<Stack>
											<MyLabelField>
												<FormattedMessage id="lead" />
											</MyLabelField>
											{taskId && !leadId ? (
												<React.Fragment>
													<MySelectField
														size="small"
														name="lead_id"
														value={formik.values.lead_id}
														onChange={formik.handleChange}
													>
														{leadLst &&
															leadLst.map((leadElmt) => {
																return (
																	<MenuItem key={`task-frm-lead-id-${leadElmt.id}`} value={leadElmt.id}>
																		{leadElmt.name}
																	</MenuItem>
																);
															})}
													</MySelectField>
													{formik.errors.lead_id && (
														<FormHelperText error> {formik.errors.lead_id} </FormHelperText>
													)}
												</React.Fragment>
											) : (
												<Box>
													<Box
														sx={{
															border: `1px solid ${theme.palette.grey[500]}`,
															borderRadius: 2,
															p: 1
														}}
													>
														{leadNamesSelected && leadNamesSelected.length > 0 && (
															<Box display="flex" columnGap={1} rowGap={1} flexWrap="wrap" sx={{ mb: 1 }}>
																{leadNamesSelected.map((elmt, idx) => {
																	return (
																		<Chip
																			key={`taskfrm-leadname-chip-${idx}`}
																			label={elmt}
																			onDelete={handleDeleteChipLeadName(idx)}
																			variant="outlined"
																			chipcolor="success"
																		/>
																	);
																})}
															</Box>
														)}
														<MyTextField
															size="small"
															name="lead_name"
															value={formik.values.lead_name}
															onMouseDown={handleLeadNameMouseDown}
															onChange={handleLeadNameChange}
															fullWidth
															autoComplete="false"
															sx={{
																" input": { border: "0 solid", outline: "none" },
																" fieldset": { border: 0 }
															}}
														/>
													</Box>
													{leadLst && leadLst.length > 0 && isOpenListLeadItem && (
														<Box sx={{ mt: 1 }}>
															<Box component="div" display="flex" justifyContent="flex-end">
																<Box
																	display="flex"
																	justifyContent="center"
																	alignItems="center"
																	sx={{
																		width: 30,
																		height: 30,
																		background: theme.palette.error.main,
																		borderRadius: "50%",
																		boxShadow: 1
																	}}
																>
																	<IconButton
																		sx={{ color: "white" }}
																		size="small"
																		onClick={handleCloseListLeadName}
																	>
																		<IconX />
																	</IconButton>
																</Box>
															</Box>
															<List
																sx={{
																	height: "200px",
																	overflowX: "hidden",
																	boxShadow: 3,
																	borderRadius: 2,
																	mt: 1
																}}
																component="nav"
																aria-label="secondary mailbox folder"
															>
																{leadLst.map((leadItem, idxLead) => {
																	return (
																		<ListItemButton
																			key={`taskfrm-lead-item-${idxLead}`}
																			onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
																				handleLeadSelect(e, leadItem.name)
																			}
																		>
																			<ListItemText primary={leadItem.name} />
																		</ListItemButton>
																	);
																})}
															</List>
														</Box>
													)}
												</Box>
											)}
										</Stack>
									</Grid>
								) : (
									<React.Fragment></React.Fragment>
								)}
								<Grid item xs={12}>
									<Stack>
										<MyLabelField>
											<FormattedMessage id="description" />
										</MyLabelField>
										<MyTextField
											fullWidth
											rows={3}
											name="description"
											value={formik.values.description}
											placeholder={t("description")}
											multiline
											onChange={formik.handleChange}
											onMouseDown={handleCloseListLeadName}
										/>
									</Stack>
								</Grid>
								<Grid item xs={12}>
									<Stack>
										<MyLabelField>
											<FormattedMessage id="reminder" />
										</MyLabelField>
										<MySelectField
											size="small"
											name="reminder_time_type"
											value={formik.values.reminder_time_type}
											onChange={formik.handleChange}
											onMouseDown={handleCloseListLeadName}
										>
											{reminderLst &&
												reminderLst.map((elmt) => {
													return (
														<MenuItem key={`task-frm-reminder-time-type-${elmt.type}`} value={elmt.type}>
															{elmt.name}
														</MenuItem>
													);
												})}
										</MySelectField>
										{formik.errors.reminder_time_type && (
											<FormHelperText error> {formik.errors.reminder_time_type} </FormHelperText>
										)}
									</Stack>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<AnimateButton>
								<Stack direction="row" spacing={1}>
									{taskId && !leadId ? (
										<Button
											variant="contained"
											type="submit"
											startIcon={<SaveOutlinedIcon />}
											sx={{
												bgcolor: "primary.main",
												"&:hover": {
													bgcolor: "primary.dark"
												}
											}}
										>
											<FormattedMessage id="update" />
										</Button>
									) : (
										<Button
											variant="contained"
											type="submit"
											startIcon={<AddIcon />}
											sx={{
												bgcolor: "primary.main",
												"&:hover": {
													bgcolor: "primary.dark"
												}
											}}
										>
											<FormattedMessage id="create" />
										</Button>
									)}
									<Button variant="outlined" color="error" startIcon={<CloseIcon />} onClick={closeTaskFrm}>
										<FormattedMessage id="close" />
									</Button>
								</Stack>
							</AnimateButton>
						</DialogActions>
					</form>
				)}
			</Dialog>
		);
	}
);
export default TaskFrm;
