import React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
// material-ui
import {
	Avatar,
	Box,
	Button,
	CardActions,
	Chip,
	ClickAwayListener,
	Divider,
	Grid,
	Paper,
	Popper,
	Stack,
	Typography,
	useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import NotificationList from "./NotificationList";

// assets
import { IconBell } from "@tabler/icons";
import { realtimeDb } from "configs/firebase";
import { onValue, ref } from "firebase/database";
import TaskFrm from "views/task/TaskFrm";

import { END_POINT } from "configs";
import useAuth from "hooks/useAuth";
import useConfig from "hooks/useConfig";
import { useTranslation } from "react-i18next";
import { useDispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import INotification from "types/i-notification";
import { ITask } from "types/i-task";
import { UserProfile } from "types/user-profile";
import axios from "utils/axios";
import AskTypeMeeting from "views/lead/popup/AskTypeMeeting";
import ChangeDeadlineConfirmedDialog from "views/task/ChangeDeadlineConfirmedDialog";
import DoneConfirmedDialog from "views/task/DoneConfirmedDialog";
import HowApproachDialog from "views/task/HowApproachDialog";
import HowCallDoneApproach from "views/task/HowCallDoneApproach";
import WhatMeeting from "views/task/WhatMeeting";
// notification status options
const status = [
	{
		value: "all",
		label: "All Notification"
	},
	{
		value: "new",
		label: "New"
	},
	{
		value: "unread",
		label: "Unread"
	},
	{
		value: "other",
		label: "Other"
	}
];

// ==============================|| NOTIFICATION ||============================== //
interface IMarkReadSaved {
	id?: string | null;
	mark_all: boolean;
}
interface IRealTimeSaved {
	id: string;
	is_read_realtime: boolean;
}
interface IViewNotificationSaved {
	is_viewed_notification: boolean;
}
interface ITaskChangedStatus {
	id: number[];
	status: string;
}
const NotificationSection = () => {
	const theme = useTheme();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { user }: any = useAuth();
	const matchesXs = useMediaQuery(theme.breakpoints.down("md"));
	const [isOpenTaskFrm, setOpenTaskFrm] = React.useState<boolean>(false);
	const [open, setOpen] = useState(false);
	const [total, setTotal] = useState<number>(0);
	const [notificationLst, setNotificationLst] = React.useState<INotification[]>([]);
	const [newNotification, setNewNotification] = React.useState<boolean>(false);
	const [isOpenChangeDeadlineConfirmedDialog, setOpenChangeDeadlineConfirmedDialog] = React.useState<boolean>(false);
	const [taskId, setTaskId] = React.useState<number>(0);
	const [notiId, setNotiId] = useState<string>("");
	var mounted: boolean = true;
	const [perpage, setPerpage] = React.useState<number>(10);
	const notificationRef = ref(realtimeDb, "notifications");
	const [isOpenWhatMeeting, setOpenWhatMeeting] = React.useState<boolean>(false);
	let [leadId, setLeadId] = React.useState<number>(0);
	/**
	 * anchorRef is used on different componets and specifying one type leads to other components throwing an error
	 * */
	const anchorRef = useRef<any>(null);

	const handleToggle = async () => {
		let dataSaved: IViewNotificationSaved = {
			is_viewed_notification: true
		};
		const res = await axios.put(END_POINT.API_NOTIFICATION_UPDATE_IS_VIEWED_RED, dataSaved);
		setNewNotification(false);
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const prevOpen = useRef(open);
	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}
		prevOpen.current = open;
	}, [open]);
	useEffect(() => {
		const init = async () => {
			const rs: any = await axios.get(END_POINT.API_GET_USER_INFO);
			if (mounted) {
				const { status, data } = rs.data;
				if (status) {
					let userInfo: UserProfile = data.items;
					let isNewNotification = false;
					if ((userInfo.is_viewed_notification as Boolean) === false) {
						isNewNotification = true;
					}
					setNewNotification(isNewNotification);
				}
			}
		};
		init();
		return () => {
			mounted = false;
		};
	}, []);
	const loadNotificationLst = async () => {
		try {
			if (open) {
				const rsNoti: any = await axios.get(END_POINT.API_GET_NOTIFICATION, { params: { item: perpage } });
				if (mounted) {
					const { status, data } = rsNoti.data;
					if (status) {
						let notificationData: INotification[] = data.items ? data.items : [];
						let totalItem: number = notificationData.length;
						setNotificationLst(notificationData);
						setTotal(totalItem);
					}
				}
			}
		} catch (err: any) {
			dispatch(
				openSnackbar({
					open: true,
					message: err && err.data && err.data.exception ? err.data.exception : "",
					anchorOrigin: { vertical: "bottom", horizontal: "left" },
					variant: "alert",
					alert: {
						color: "error"
					},
					transition: "Fade",
					close: false
				})
			);
		}
	};
	useEffect(() => {
		loadNotificationLst();
		return () => {
			mounted = false;
		};
	}, [perpage, open]);
	const handleOpenTaskFrm = () => {
		setOpenTaskFrm(true);
	};
	const handleCloseTaskFrm = useCallback(() => {
		setOpenTaskFrm(false);
	}, []);
	const handleMarkAsAllRead = async () => {
		try {
			let data: IMarkReadSaved = {
				id: null,
				mark_all: true
			};
			const res: any = await axios.put(`${END_POINT.API_NOTIFICATION_MARK_READ}`, data);
			const { status } = res.data;
			if (status) {
				let notificationData: INotification[] = res.data.data.items ? res.data.data.items : [];
				let totalItem: number = notificationData.length;
				loadNotificationLst();
				setTotal(totalItem);
				setNewNotification(false);
			}
		} catch (err: any) {
			dispatch(
				openSnackbar({
					open: true,
					message: err && err.data && err.data.exception ? err.data.exception : "",
					anchorOrigin: { vertical: "bottom", horizontal: "left" },
					variant: "alert",
					alert: {
						color: "error"
					},
					close: false
				})
			);
		}
	};
	useEffect(() => {
		const subscriber = onValue(notificationRef, async (snapshot) => {
			const notiArray: any = Object.values(snapshot.val());
			const notiLength = notiArray.filter((item: any) => item.user_id === user.id).length;
			if (notiLength > 0) {
				setTimeout(async () => {
					const res = await axios.get(END_POINT.API_GET_NOTIFICATION, { params: { item: perpage } });
					const { data } = res.data;
					let notifiLst: INotification[] = data.items && data.items.length > 0 ? data.items : [];
					let totalItem: number = notifiLst.length;
					setNotificationLst(notifiLst);
					setTotal(totalItem);
					if (notifiLst.length > 0) {
						if (notifiLst[0].is_read === false && notifiLst[0].is_read_realtime === false) {
							setNewNotification(true);
							if (notifiLst[0].type == "remind_task_meeting" || notifiLst[0].type == "change_deadline_task") {
								setTaskId(notifiLst[0].object_id);
							} else {
								dispatch(
									openSnackbar({
										open: true,
										title: notifiLst[0].title,
										message: notifiLst[0].message,
										anchorOrigin: { vertical: "bottom", horizontal: "left" },
										variant: "noti",
										alert: {
											color: "success"
										},
										close: false,
										item: notifiLst[0]
									})
								);
							}
							let dataRealTime: IRealTimeSaved = {
								id: notifiLst[0].id,
								is_read_realtime: true
							};
							console.log(notifiLst);
							const resShotRealtime: any = await axios.put(END_POINT.API_NOTIFICATION_UPDATE_REALTIME, dataRealTime);
						}
					}
				}, 5000);
			}
		});
		return subscriber;
	}, []);
	const handleConfirmTaskDone = (notiItem: INotification) => {
		let taskId: number = notiItem && notiItem.object_id ? parseInt(notiItem.object_id.toString()) : 0;
		if (taskId) {
			let idData: number[] = [taskId];
			let updateTaskParams: any = {
				id: idData,
				status: "is_completed"
			};
			const promise1: any = axios.get(`/task/show/${taskId}`);
			const promise2: any = axios.put("/task/change-status", updateTaskParams, { headers: { isShowLoading: true } });
			Promise.all([promise1, promise2]).then((vals: any) => {
				const res1: any = vals[0];
				const res2: any = vals[1];
				let type: string = "";
				let taskPerform: string = "";
				let step: number = 0;
				if (res1.data.status) {
					type = res1.data.data.items.type;
					taskPerform = res1.data.data.items.task_perform;
					step = res1.data.data.items.lead && res1.data.data.items.lead.step ? parseInt(res1.data.data.items.lead.step) : 0;
					setLeadId(parseInt(res1.data.data.items.lead.id));
				}
				if (res2.data.status) {
					if (type === "meeting" && step > 0) {
						setOpenWhatMeeting(true);
					}
				}
				loadNotificationLst();
			});
		}
	};
	const handleConfirmTaskNotYet = (notiItem: INotification) => {
		let objectId: number = notiItem && notiItem.object_id ? parseInt(notiItem.object_id.toString()) : 0;
		setTaskId(objectId);
		setNotiId(notiItem.id);
		setOpenChangeDeadlineConfirmedDialog(true);
	};
	const handleCloseChangeDeadlineConfirmedDialog = useCallback(async () => {
		setOpenChangeDeadlineConfirmedDialog(false);
	}, []);
	const handleViewMore = () => {
		setPerpage((prevState) => prevState + 10);
	};
	const handleSetTaskId = (id: number) => {
		setTaskId(id);
	};
	const handleCloseWhatMeeting = React.useCallback(() => {
		setOpenWhatMeeting(false);
	}, []);
	return (
		<React.Fragment>
			<Box
				sx={{
					ml: 2,
					mr: 3,
					[theme.breakpoints.down("md")]: {
						mr: 2
					},
					position: "relative"
				}}
			>
				<Avatar
					variant="rounded"
					sx={{
						...theme.typography.commonAvatar,
						...theme.typography.mediumAvatar,
						transition: "all .2s ease-in-out",
						background: theme.palette.mode === "dark" ? theme.palette.dark.main : theme.palette.success.light,
						color: theme.palette.mode === "dark" ? theme.palette.warning.dark : theme.palette.success.dark,
						'&[aria-controls="menu-list-grow"],&:hover': {
							background: theme.palette.mode === "dark" ? theme.palette.warning.dark : theme.palette.success.dark,
							color: theme.palette.mode === "dark" ? theme.palette.grey[800] : theme.palette.success.light
						},
						overflow: "visible",
						width: 45,
						height: 45
					}}
					ref={anchorRef}
					aria-controls={open ? "menu-list-grow" : undefined}
					aria-haspopup="true"
					onClick={handleToggle}
					color="inherit"
				>
					{newNotification && (
						<Box
							sx={{
								width: "8px",
								height: "8px",
								position: "absolute",
								top: "-3px",
								right: "-1px",
								background: theme.palette.error.main,
								borderRadius: "50%"
							}}
						></Box>
					)}

					<IconBell stroke={1.5} size={25} />
				</Avatar>
			</Box>

			<Popper
				placement={matchesXs ? "bottom" : "bottom-end"}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				popperOptions={{
					modifiers: [
						{
							name: "offset",
							options: {
								offset: [matchesXs ? 5 : 0, 20]
							}
						}
					]
				}}
			>
				{({ TransitionProps }) => (
					<ClickAwayListener onClickAway={handleClose}>
						<Transitions position={matchesXs ? "top" : "top-right"} in={open} {...TransitionProps}>
							<Paper>
								{open && (
									<MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
										<Grid container direction="column" spacing={2}>
											<Grid item xs={12}>
												<Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
													<Grid item>
														<Stack direction="row" spacing={2}>
															<Typography variant="subtitle1">{t("all_notification")}</Typography>
															<Chip
																size="small"
																label={total}
																sx={{
																	color: theme.palette.background.default,
																	bgcolor: theme.palette.warning.dark
																}}
															/>
														</Stack>
													</Grid>
													<Grid item>
														<Typography
															component={Link}
															to="#"
															variant="subtitle2"
															color="primary"
															onClick={handleMarkAsAllRead}
														>
															{t("mark_as_all_read")}
														</Typography>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<PerfectScrollbar
													style={{ height: "100%", maxHeight: "calc(100vh - 205px)", overflowX: "hidden" }}
												>
													<NotificationList
														notificationLst={notificationLst}
														loadNotificationLst={loadNotificationLst}
														onToggle={handleToggle}
														onOpenTaskFrm={handleOpenTaskFrm}
														onConfirmTaskDone={handleConfirmTaskDone}
														onConfirmTaskNotYet={handleConfirmTaskNotYet}
														onSetTaskId={handleSetTaskId}
													/>
												</PerfectScrollbar>
											</Grid>
										</Grid>
										<Divider />
										<CardActions sx={{ p: 1.25, justifyContent: "center" }}>
											<Button size="small" disableElevation onClick={handleViewMore}>
												{t("view_more")}
											</Button>
										</CardActions>
									</MainCard>
								)}
							</Paper>
						</Transitions>
					</ClickAwayListener>
				)}
			</Popper>
			<TaskFrm isOpenTaskFrm={isOpenTaskFrm} closeTaskFrm={handleCloseTaskFrm} taskId={taskId} />
			<ChangeDeadlineConfirmedDialog
				onCloseChangeDeadlineConfirmedDialog={handleCloseChangeDeadlineConfirmedDialog}
				isOpenChangeDeadlineConfirmedDialog={isOpenChangeDeadlineConfirmedDialog}
				taskId={taskId}
				notiId={notiId}
				loadNotificationLst={loadNotificationLst}
			/>
			<WhatMeeting isOpenWhatMeeting={isOpenWhatMeeting} onCloseWhatMeeting={handleCloseWhatMeeting} leadId={leadId} />
		</React.Fragment>
	);
};

export default NotificationSection;
