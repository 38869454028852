// third-party
import { FormattedMessage } from 'react-intl';

// assets
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import { ROUTE_NAME } from 'configs';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const task = {
    id: 'task',
    title: <FormattedMessage id="task" />,
    type: 'group',
    url: ROUTE_NAME.TASK_LIST,
    icon: PlaylistAddCheckOutlinedIcon,
    breadcrumbs: true,
};

export default task;
