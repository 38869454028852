// third-party
import { FormattedMessage } from 'react-intl';

import { IconChartDonut2 } from '@tabler/icons';
import { ROUTE_NAME } from 'configs';

const pointSystemManager = {
    id: 'point_system_manager',
    title: <FormattedMessage id="point_system" />,
    type: 'group',
    url: '/point-system-manager',
    icon: IconChartDonut2,
    breadcrumbs: true,
};

export default pointSystemManager;
