import { useEffect, useState } from 'react';
// material-ui
import { Typography, Avatar } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { menuItemsManager, menuItemsSeller } from 'menu-items';
import { memo } from 'react';
import { NavItemTypeObject } from 'types';
import NavCollapse from './NavCollapse/index';
import NavItem from './NavItem';
import produce from 'immer';
import { setNestedObjectValues } from 'formik';
import { NavItemType } from 'types';
import { END_POINT } from 'configs';
import axios from 'utils/axios';
import auth_service from 'utils/authService';
import { useDispatch, useSelector } from 'store';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { user } = useAuth();
    // let menulst: NavItemType[] = user && user.type === 'manager' ? menuItemsManager.items : menuItem.items;
    const [menulst, setMenuLst] = useState<NavItemType[]>(user && user.type === 'manager' ? menuItemsManager.items : menuItemsSeller.items);
    const { isFetch } = useSelector((state) => state.fetch);
    const firstNav: any = {
        id: 'avt',
        title: user?.name ?? "",
        type: 'group',
        url: 'none',
        icon: SettingsOutlinedIcon,
        breadcrumbs: true,
        cursor_point: true
    }
    useEffect(() => {
        const getRole = async () => {
            const accessToken: string = auth_service.getAccessToken();
            let res: any = await axios.post(END_POINT.API_AUTHENTICATED, { token: accessToken });
            if (res.status) {
                let data = res.data.data.data.user;
                setMenuLst(data?.type === 'manager' ? menuItemsManager.items : menuItemsSeller.items);
            }
        };
        getRole();
    }, [isFetch]);
    const avt: any = <><NavItem key={0} item={firstNav} level={1} /></>;
    const navItems = menulst.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavItem key={item.id} item={item} level={1} />;
            case 'collapse':
                return <NavCollapse key={item.id} menu={item} level={1} />;
            case 'item':
                return <NavItem key={item.id} item={item} level={1} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{avt}{navItems}</>;
};

export default memo(MenuList);
