// third-party
import { createSlice } from '@reduxjs/toolkit';
import { collection, getDocs, addDoc, doc, setDoc, Timestamp, getDoc } from 'firebase/firestore';
import { db } from 'configs/firebase';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// types
import { DefaultRootStateProps } from 'types';
import { ChatHistory } from 'types/chat';
import { END_POINT } from 'configs';
import { ENCRYPTION_KEY } from 'store/constant';
var CryptoJS = require('crypto-js');
// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['chat'] = {
    error: null,
    chats: [],
    user: {},
    users: [],
};

const slice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET USER
        getUserSuccess(state, action) {
            state.user = action.payload;
        },

        // GET USER CHATS
        getUserChatsSuccess(state, action) {
            state.chats = action.payload;
        },

        // GET USERS
        getUsersSuccess(state, action) {
            state.users = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function getUser(id: number) {
    return async () => {
        try {
            const chatRef = collection(db, 'messages');
            getDocs(chatRef)
                .then((res) => {
                    const chatRoom = res.docs.map((doc) => ({
                        data: doc.data(),
                        id: doc.id,
                    }));
                })
                .catch((e) => console.log(e));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getUserChats(fromUser: number | undefined, toUser: number | undefined) {
    return async () => {
        if (fromUser && toUser) {
            const path = fromUser < toUser ? `${fromUser}-${toUser}` : `${toUser}-${fromUser}`;
            try {
                const docRef = doc(db, 'messages', path);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const messageList = Object.values(docSnap.data()).sort((a, b) => a.time - b.time);
                    dispatch(slice.actions.getUserChatsSuccess(messageList));
                } else {
                    await setDoc(doc(db, 'messages', path), {});
                }
            } catch (error) {
                console.log(error);
                dispatch(slice.actions.hasError(error));
            }
        }
    };
}

export function insertChat(chat: ChatHistory, fromUser: number | undefined, toUser?: number | undefined) {
    return async () => {
        if (fromUser && toUser) {
            const path = fromUser < toUser ? `${fromUser}-${toUser}` : `${toUser}-${fromUser}`;
            const timestamp = Timestamp.now().toMillis();
            try {
                return await setDoc(doc(db, 'messages', path), { [`${timestamp}`]: chat }, { merge: true });
            } catch (error) {
                dispatch(slice.actions.hasError(error));
            }
        }
    };
}

export function getUsers() {
    return async () => {
        try {
            const response = await axios.get(END_POINT.API_GET_USERS);
            const { data } = response.data;
            dispatch(slice.actions.getUsersSuccess(data.items));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getChatMessage(messageList: any[]) {
    return () => {
        dispatch(slice.actions.getUserChatsSuccess(messageList));
    };
}
