import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'store';
import { hideNotify } from 'store/slices/notify';

function Notify() {
    const dispatch = useDispatch();
    function handleClose(): void {
        dispatch(hideNotify());
    }
    useEffect(() => {
        const timeout = setTimeout(() => {
            dispatch(hideNotify());
        }, 999999);
        return () => {
            clearTimeout(timeout);
        };
    });
    let alertHtml: React.ReactNode = null;
    const { isShow, typeMsg, dataMsg } = useSelector((state) => state.notify);
    let displayNotify: string | null = '';
    if (isShow && dataMsg && dataMsg.length > 0) {
        alertHtml = dataMsg.map((item, idx) => {
            return (
                <li key={idx}>
                    <strong>{item}</strong>
                </li>
            );
        });
        displayNotify = 'flex';
    }
    let bgColor: string | null = '';
    switch (typeMsg) {
        case 'danger':
            bgColor = '#ff0000';
            break;
        case 'warning':
            bgColor = '#cfb900';
            break;
        case 'success':
            bgColor = '#00af04';
            break;
    }
    return (
        <React.Fragment>
            {isShow && (
                <div
                    style={{
                        display: displayNotify,
                        zIndex: '9991',
                        backgroundColor: 'rgba(0, 0, 0, 0.76)',
                        position: 'fixed',
                        top: '0',
                        left: '0',
                        width: '100vw',
                        height: '100vh',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            width: '400px',
                            position: 'relative',
                            padding: '10px 10px',
                            borderRadius: '3px',
                            backgroundColor: bgColor,
                        }}
                    >
                        <button
                            type="button"
                            name="btnClose"
                            style={{
                                position: 'absolute',
                                top: '-30px',
                                right: '-35px',
                                background: 'transparent',
                                border: '0',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: '#fff',
                                cursor: 'pointer',
                            }}
                            onClick={handleClose}
                        >
                            <HighlightOffIcon sx={{ fontSize: 35 }} />
                        </button>
                        <ul style={{ margin: 0, padding: 0, listStyle: 'none', color: '#FFF' }}>{alertHtml}</ul>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

export default Notify;
